import styled from "styled-components";
import right from "../../../images/rightPurple.png";

export const StyledSection = styled.section`
  background-image: url(${right});
  background-color: white;
  background-size: cover;

  padding: 0 0 100px 0;

  min-height: 600px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  background-position: center;
  @media (max-width: 800px) {
    align-items: center;
    justify-content: center;
    /* height: 2610px; */
    flex-direction: column;
  }


  @media (max-width: 800px) {
    padding: 0 0 150px 0;
  }

  @media (max-width: 767px) {
    display: none;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const StyledH1 = styled.section`
  height: 59px;

  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 1.4em;
  text-align: center;
  color: #343f8a;
`;

export const StyledP = styled.p`
  height: 26px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.8em;

  text-align: center;

  color: #9599bb;
`;

export const StyledHead = styled.p`
  display: flex;
  justify-content: center;
  align-itens: center;
  text-align: center;
  flex-direction: column;
`;

export const StyledDiv = styled.div`
  min-height: auto;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;

  margin-top: 50px;
`;

export const StyledDiv1 = styled.div`
  width: 100%;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const Box = styled.div`
  min-height: ${(props) => (props.size ? "auto" : "300px")};
  width: ${(props) => props.size && "47vw"};

  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justify};

  @media (max-width: 1000px) {
    align-items: center;
    width: 47vw;
  }
`;

export const StyledSection2 = styled.section`
  height: 450px;

  justify-content: center;
  text-align: center;
  align-items: center;

  position: relative;

  background-color: white;
  background-position: center;

  display: none;
  justify-content: start;
  @media (max-width: 767px) {
    align-items: center;
    justify-content: start;
    flex-direction: column;
    min-height: 822px;
    display: flex;
    padding-bottom: 113px;
  }

	@media (max-width: 489px) {
    min-height: 850px;
  }

	@media (max-width: 489px) {
    min-height: 900px;
    padding-bottom: 140px;
  }

  @media (max-width: 435px) {
    padding-bottom: 550px;
		min-height: 955px;
  }
  @media (max-width: 380px) {
    padding-bottom: 570px;
		min-height: 975px;
  }
`;

export const StyledH2 = styled.h1`
  margin-bottom: 20px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 1.4rem;
  text-align: left;
  color: #343f8a;

  @media (max-width: 1000px) {
    text-align: center;
  }
`;

export const StyledP2 = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #9599bb;
  text-align: left;

  @media (max-width: 1000px) {
    text-align: center;
  }
`;

export const StyledButton = styled.button`
box-sizing: border-box;
width: 160px;
height: 44px;

background: #009EE2;
border: 2px solid #009EE2;
border-radius: 4px;
margin-top 5px;
`;

export const StyledP3 = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;

  color: #ffffff;
`;
export const Styleddiv2 = styled.div`
  flex-direction: column;
  display: flex;
  width: 300px;
  height: 300px;
  align-items: center;
  justify-content: center;
  align-items: start;

  @media (max-width: 1000px) {
    align-items: center;
  }

  @media (max-width: 800px) {
    box-sizing: border-box;
		align-items: center;
    height: 160px;
  }
`;

export const Styleddiv3 = styled.div`
  flex-direction: column;
  display: flex;
  width: 100vw;
  /* height: 580px; */
  align-items: center;
  justify-content: space-around;
`;
