import HeaderComponent from "../Home/Header";
import FooterComponent from "../Home/Footer";
import MainComponent from "./Main";
import IntroComponent from "./Intro";
import ReadyComponent from "../Home/Ready";
import BankComponent from "./Bank";
import { StyledApp } from "./style";
import { useEffect, useState } from "react";
import SideModal from "../SideModal";

function BusinessComponent() {
  const [showModal, setShowModal] = useState(false);
  const [modalAnimation, setModalAnimation] = useState(0);

  useEffect(() => {
    showModal === true
      ? setTimeout(() => {
          modalAnimation ? setShowModal(true) : setShowModal(false);
        }, 490)
      : modalAnimation
      ? setShowModal(true)
      : setShowModal(false);
  }, [modalAnimation]);
    return (
      <StyledApp>
              {showModal && (
        <SideModal
          setModalAnimation={setModalAnimation}
          modalAnimation={modalAnimation}
        />
      )}
      <HeaderComponent
        setModalAnimation={setModalAnimation}
        modalAnimation={modalAnimation}
      />
        <MainComponent />
        <IntroComponent />
        <BankComponent />
        <ReadyComponent />
        <FooterComponent />
      </StyledApp>
    );
  }
  
  export default BusinessComponent;