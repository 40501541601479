import styled from "styled-components";

export const Container = styled.div`
  flex: 1 0 auto;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 180px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  width: 1140px;
  height: 422px;
  border-radius: 20px;
  background-color: #9599bb;

  img {
    width: 50%;
    height: 422px;
    border-radius: 0 20px 20px 0;
  }

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    border-radius: 0 20px 0 20px;
    img {
      width: 100%;
      border-radius: 0 20px 0 0;
    }
  }

  @media (max-width: 650px) {
    height: 621px;
  }

  @media (max-width: 800px) and (min-width: 651px) {
    height: 535px;
    img {
      height: 263px;
    }
  }

  @media (max-width: 1220px) {
    width: 90%;
  }
`;

export const BoxInfo = styled.div`
  background-color: #f5f9ff;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: start;
  font-family: "Poppins", sans-serif;
  padding: 60px;


  h2 {
    color: #343f8a;
    font-size: 42px;
    font-weight: 600;
    line-height: 1.4em;
    letter-spacing: normal;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: #9599bb;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    background-color: #343f8a;
    color: #ffffff;
    padding: 20px 30px;
    border-radius: 10px;
    border: #343f8a solid 1px;
    margin-top: 40px;
    cursor: pointer;
    p {
      color: #ffffff;
      margin-right: 8px;
    }
    &:hover {
      background-color: #ffffff;
      color: #343f8a;
      border-color: #343f8a;
      p {
        color: #343f8a;
      }
    }
  }

  @media (max-width: 650px) {
    height: 358px;
  }
  @media (min-width: 800px) {
  width: 50%;
  }
  @media (max-width: 800px) {
    padding: 20px;
    align-items: center;
    text-align: center;
    border-radius: 0 0 0 20px;

    h2 {
      font-size: 28px;
    }
    button {
      margin-top: 20px;
    }
  }

  @media (max-width: 800px) and (min-width: 651px) {
    height: 272px;
  }

  @media (max-width: 1220px) and (min-width: 801px) {
    padding: 20px 30px 20px 20px;
    h2 {
      font-size: 33px;
    }
  }
`;
