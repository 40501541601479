import { useSelector } from "react-redux";
import openAccount from "../../../images/openAccount.jpg";
import { FadeInUp } from "../../../Styles";
import { BoxInfo, Card, Container } from "./style";
import { FaArrowRight } from "react-icons/fa";

const OpenAccount = () => {
  let token = useSelector((state) => state).language.Language;

  return (
    <FadeInUp duration="1.5s" delay="1s">
      <Container>
        <Card>
          <BoxInfo>
            <h2>Abra sua conta, sem custos</h2>
            <p>
              A PixWave é um banco digital que torna a sua vida financeira cada
              vez mais segura, simples e eficaz. Chegamos para revolucionar o
              mercado financeiro.
            </p>
            <button>
              <a href="https://bank.pixwave.com.br/type-create-account">Abra sua conta</a>
              <FaArrowRight />
            </button>
          </BoxInfo>
          <img
            src={openAccount}
            alt={
              token === "Portugues"
                ? "Mulher com notebook"
                : "Woman with notebook"
            }
          />
        </Card>
      </Container>
    </FadeInUp>
  );
};

export default OpenAccount;
