import styled from "styled-components";
import circle from "../../../images/circleComplete.png";

export const Container = styled.div`
  flex: 1 0 auto;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  position: relative;

  .purple {
    position: absolute;
    right: 0;
    height: 100%;

    @media (max-width: 1350px) {
      height: 80%;
    }

    @media (max-width: 1050px) {
      height: 50%;
    }

    @media (max-width: 664px) {
      height: 40%;
      top: 15%;
    }

    @media (max-width: 650px) {
      height: 70%;
      top: -35px;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1140px;

  @media (max-width: 1050px) {
    flex-direction: column-reverse;
    margin-bottom: 20px;
    width: 90%;
  }
`;

export const BoxImg = styled.div`
  background-image: url(${circle});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 570px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 650px) {
    width: 90%;
    height: 258px;

    img {
      width: 329px;
    }
  }

  @media (max-width: 800px) and (min-width: 651px) {
    width: 100%;
    height: 514px;
    img {
      width: 603px;
      height: 474px;
    }
  }

  @media (max-width: 1220px) and (min-width: 800px) {
    width: 700px;
    img {
      width: 749px;
      height: 589px;
    }
  }
`;

export const BoxInfo = styled.div`
  width: 520px;

  h2 {
    color: #343f8a;
    font-size: 42px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    line-height: 1.4em;
  }

  p {
    line-height: 1.8em;
    color: #9599bb;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
  }

  @media (max-width: 650px) {
    width: 100%;
    text-align: center;

    h2 {
      font-size: 28px;
    }

    p {
      font-size: 14px;
      margin-top: 20px;
    }
  }

  @media (max-width: 800px) and (min-width: 651px) {
    width: 100%;
    text-align: center;

    h2 {
      font-size: 28px;
    }
    p {
      font-size: 14px;

      margin-top: 20px;
    }
  }

  @media (max-width: 1220px) and (min-width: 800px) {
    width: 100%;
    text-align: center;

    h2 {
      font-size: 33px;
    }
  }
`;
