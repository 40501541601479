import {
  BoxImage,
  Container,
  Header,
  Image,
  InfoText,
  Opacity,
  TextArea,
} from "./style";
import happy from "../../../images/Happy.jpg";
import { FadeIn, FadeInUp } from "../../../Styles";
import { useSelector } from "react-redux";

const ExchangeComponent = () => {
  let token = useSelector((state) => state).language.Language;

  return (
    <Container>
      <Opacity></Opacity>
      <InfoText>
        <FadeInUp duration="1.5s" delay="1s">
          <Header>Câmbio Exchange</Header>
        </FadeInUp>
        <TextArea>
          Sua conta PixWave é global, Faça câmbio pela plataforma com a cotação
          comercial de mais de 100 moedas e economize.
        </TextArea>
      </InfoText>

      <BoxImage>
        <FadeIn duration="1.5s" delay="1s">
          <Image
            src={happy}
            alt={token === "Portugues" ? "Pessoa felis" : "Pessoa felis"}
          />
        </FadeIn>
      </BoxImage>
    </Container>
  );
};

export default ExchangeComponent;
