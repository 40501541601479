import {
  BoxOpacity,
  BoxSection,
  ContainerHeader,
  ContainerMain,
  Header,
  Menu,
} from "./style";
import { FadeInUp } from "../../../Styles";
import {
  BiRightArrowAlt
} from "react-icons/bi";

const MainComponent = ({ text, home }) => {
  return (
    <ContainerMain>
      <BoxSection>
        <BoxOpacity>
          <ContainerHeader>
            <FadeInUp duration="1.5s" delay="1s">
                <>
                  <Header>{text}</Header>
                  <Menu>
                    <li>
                      <a href="/">{home}</a>
                    </li>
                    <BiRightArrowAlt />
                    <li>{text}</li>
                  </Menu>
                </>
            </FadeInUp>
          </ContainerHeader>
        </BoxOpacity>
      </BoxSection>
    </ContainerMain>
  );
};

export default MainComponent;
