import styled from 'styled-components'
import mainImg from '../../../images/leftBackground.png'

export const StyledSection = styled.section`
background-image: url(${mainImg});
background-color: white;
background-size: cover;
height: 800px;
text-align: center;
position: relative;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;

@media (max-width: 800px) {
    height: 1350px;
}

`

export const StyledDiv = styled.div`
text-align: center;

width: 75%;
height: 80%;
display: flex;
justify-content: center;
align-items: center;

@media (max-width: 800px) {
    flex-direction: column;
    justify-content: start;
    width: 100%;
}

`

export const StyledDivTitle = styled.div`
width: 1319px;
height: 100px;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 42px;
line-height: 59px;
/* or 140% */

text-align: center;

color: #051C1D;

@media (max-width: 650px) {
    font-size: 30px;

    margin-top: 30px;
}

@media (max-width: 400px) {
    font-size: 25px;
}

`

export const StyledBr = styled.br`

@media (max-width: 800px) {
display: none;
}

`

export const StyledDiv2 = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
width: 100%;

@media (max-width: 800px) {
align-items: center;
}

`

export const StyledDiv3 = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
width: 100%;
height: 40%;

@media (max-width: 800px) {
    height: unset;
}

`

export const StyledH1 = styled.h1`
color: rgb(52, 63, 138);
font-family: "Poppins", sans-serif;
font-size: 42px;
font-weight: 600;
hyphens: manual;
line-height: 58.8px;

width: 475px;
height: 120px;

text-align: left;

@media (max-width: 1200px) {
    padding-left: 25px;
    width: 435px;
}

@media (max-width: 800px) {
    text-align: center;
    padding-left: unset;
    width: 477px;
}

@media (max-width: 600px) {
    width: 380px;
    font-size: 34px;
}



`

export const StyledP = styled.p`
width: 475px;
height: 51px;

color: rgb(52, 63, 138);
font-family: "Poppins", sans-serif;
font-size: 14px;
font-weight: 400;
hyphens: manual;
line-height: 25.2px;
margin: 10px 0px 0px 0px;

text-align: left;

@media (max-width: 1200px) {
    padding-left: 25px;
    width: 435px;
}

@media (max-width: 800px) {
    text-align: center;
    padding-left: unset;
    width: 477px;
}

@media (max-width: 600px) {
    width: 350px;
}



`

export const StyledDiv4 = styled.div`
align-items: center;
display: flex;
justify-content: center;
border-style: solid;
border-width: 2px 2px 2px 2px;
border-color: #343F8A;
border-radius: 10px;
width: 275px;
height: 233px;
color: #343f8a;
flex-direction: column;
Font-family: Poppins;
Font-style: Regular;
Font-size: 22px;
Line-height: 33px;
Line-height: 100%;
Align: Center;
Vertical-align: top;
margin: 10px 10px 10px 10px;

div svg{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    fill: #343F8A;
    color: #343F8A;
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 10px;
  }


@media (max-width: 1300px) {
    width: 200px;
    height: 175px;
    max-width: 440px;
}

@media (max-width: 800px) {
    margin-top: 20px;
    width: 95%;
    height: 191px;
}

`

export const StyledDiv4blue = styled.div`
align-items: center;
display: flex;
justify-content: center;
width: 275px;
height: 233px;
color: white;
flex-direction: column;
Font family: Poppins;
Font style: Regular;
Font size: 22px;
Line height: 33px;
Line height: 100%;
Align: Center;
Vertical align: top;
border-radius: 10px;
margin: 10px 10px 10px 10px;

background-color:  #343F8A;
border-style: solid;
border-width: 2px 2px 2px 2px;
border-color: #343F8A;

div svg{
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    font-weight: 900;
    font-size: 20px;
  }

@media (max-width: 1300px) {
    width: 200px;
    height: 175px;
    max-width: 440px;
}

@media (max-width: 800px) {
    margin-top: 20px;
    width: 95%;
    height: 191px;
}

`

export const StyledDiv5 = styled.div`

display: flex;
justify-content: space-around;
margin-top: 0px;
max-width: 580px;

@media (max-width: 800px) {
    margin: 0px;
    flex-direction: column;
    align-items: center;
    max-width: 788px;
}
`

export const Styledh2 = styled.p`
flex-direction: column;
font-size: 32px;
line-height: 45px;
font-weight: 400;
`

export const StyledP2 = styled.p`
width: 175px;
height: 66px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 21px;
line-height: 33px;
margin-bottom: 0;
text-align: center;


`
export const StyledP2Center = styled.p`
width: 175px;
height: 66px;
padding-top:10px;
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 21px;
line-height: 33px;
margin-bottom: 0;
text-align: center;


`


export const Styledlogo = styled.img`
box-sizing: border-box;
width: 55px;
height: 55px;

background: url(8-03);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
border-radius: 4px;
`
export const StyledButton = styled.button`
  width: 210px;
  height: 58px;
  background: #009ee2;
  border: 2px solid #009ee2;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  margin-top: 5px;

  font-family: "Poppins", Sans-serif;
font-size: 14px;
font-weight: 400;
border-style: solid;
border-width: 0px 0px 0px 0px;
border-color: #343F8A;
border-radius: 10px 10px 10px 10px;
background-color: #343F8A;
padding: 20px 40px 20px 40px;
margin: 20px 0px 0px 0px;

@media (max-width: 800px) {
    margin: 35px 0px 0px 0px;
}



`;