import styled, { keyframes } from "styled-components";

const hideModal = keyframes`
	from {
			transform: translateX(-100%);
	}
	
	to {
      transform: translateX(0);
	}
`;

const showModal = keyframes`
	from {
		transform: translateX(0);
	}
	
	to {
		transform: translateX(-100%);
	}
`;

export const StyledModalBody = styled.section`
width: 100vw;
height: 100vh;

position: fixed;
top: 0;
left: 0;

z-index: 12;

animation: ${(props) => (props.action ? hideModal : showModal)} 0.5s;

background-color: #33333380;
`

export const StyledSideModal = styled.div`
  width: 350px;
  height: 100vh;

  position: fixed;
  left: 0;
  top: 0;

  z-index: 13;

  background-color: #0f0f0f;
  color: #ffffffc4;

  animation: ${(props) => (props.action ? hideModal : showModal)} 0.5s;

  .top-modal {
    width: 100%;
    height: 135px;
    padding: 10px 0;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const StyleImg = styled.img`
  max-width: 130px;
  max-height: 60px;

  margin: 13px 30px;
`;

export const StyleCloseButton = styled.button`
  width: 45px;

  margin: 12px 12px 12px 12px;
  border: none;

  background-color: transparent;

  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  color: #6d81c1;

  cursor: pointer;
`;

export const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    width: 305px;
    height: 35.18px;
  }
`;

export const NavAnchor = styled.a`
  color: #ffffffc4;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;

  cursor: pointer;

  :visited {
    color: #ffffffc4;
  }
`;
