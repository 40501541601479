import styled from 'styled-components'


export const StyledApp = styled.div`
background: blue;
position: relative;
overflow: hidden;
display: flex;
flex-direction: column;
min-height: 900px;

`