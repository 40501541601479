import styled from "styled-components";
import circle from "../../../images/circleComplete.png";
import mainImg from "../../../images/bg_overlay3.png";

export const Container = styled.div`
  flex: 1 0 auto;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 150px; */
  /* margin-bottom: ${(props) => props.marginBottom}; */
  align-items: flex-start;
  height: auto;
  position: relative;
  padding: 150px 0 50px 0;

  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 100px;
    align-items: center;
    margin-bottom: 100px;
  }
`;

export const Opacity = styled.div`
  background-image: url(${mainImg});
  background-position: center left;
  background-size: cover;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const InfoText = styled.div`
  margin-right: 50px;
  width: 570px;
  height: 579px;
  display: flex;
  flex-direction: column;

  justify-content: center;

  @media (max-width: 800px) {
    width: 90%;
    height: auto;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
  }
`;

export const Image = styled.div`
  border: solid none;
  box-sizing: border-box;
  width: 570px;
  height: 579px;
  background-position: center center;
  background-size: cover;
  background-image: url(${circle});

  img {
    width: 520px;
    height: 347px;
  }

  @media (max-width: 650px) {
    width: 338px;
    height: 421px;

    img {
      width: 100%;
      height: 239px;
    }
  }

  @media (max-width: 800px) and (min-width: 651px) {
    width: 633px;
    height: 618px;
    img {
      width: 633px;
      height: 436px;
    }
  }
`;

export const Header = styled.h2`
  font-family: "Poppins", sans-serif;
  color: #343f8a;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.4em;
  box-sizing: border-box;
  padding-left: 20px;

  @media (max-width: 800px) {
    font-size: 28px;
  }
`;

export const TextArea = styled.p`
  color: #9599bb;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding-left: 20px;
  background: transparent;
  margin-top: 20px;

  @media (max-width: 650px) {
    padding-left: 0px;
  }
`;
