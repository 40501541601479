import paymentImage from "../../../images/payment.png";
import { FadeIn, FadeInUp } from "../../../Styles";
import {
  Opacity,
  StyledButton,
  StyledDiv,
  StyledDiv2,
  StyledDiv3,
  StyledDiv4,
  StyledDiv6,
  StyledH1,
  StyledH2,
  StyledImg,
  StyledP,
  StyledP2,
  StyledSection,
} from "./style";

function PaymentComponent() {
  return (
    <StyledSection>
      <Opacity></Opacity>
      <StyledDiv>
        <StyledDiv6>
          <FadeInUp duration="1.5s" delay="1s">
            <StyledDiv4>
              <StyledH2>
                Pronto para <br />
                começar?
              </StyledH2>
              <StyledP2>
                Está esperando o quê? Descubra o melhor que a PixWave tem a oferecer para você.
              </StyledP2>
              <StyledButton><a href="https://bank.pixwave.com.br/type-create-account" >Abra sua conta</a></StyledButton>
            </StyledDiv4>
          </FadeInUp>
        </StyledDiv6>

        <FadeIn duration="1.5s" delay="1s">
          <StyledDiv2>
            <StyledImg src={paymentImage} />
          </StyledDiv2>
        </FadeIn>

        <FadeInUp duration="1.5s" delay="1s">
          <StyledDiv3>
            <StyledH1>
              Tecnologia de pagamento global que funciona na palma da sua mão
            </StyledH1>
            <StyledP>
              Com a PixWave, você pode enviar e receber seu dinheiro na hora,
              além de fazer a conversão e acompanhar suas movimentações de forma
              fácil e eficiente.
            </StyledP>
          </StyledDiv3>
        </FadeInUp>
      </StyledDiv>
    </StyledSection>
  );
}

export default PaymentComponent;
