import styled from "styled-components";

export const StyledFooter = styled.div`
  padding: 90px 0 0 0;
  background-color: rgb(248, 249, 251);
  color: #9599bb;
  padding-right: 15%;
  padding-left: 15%;

  @media (max-width: 800px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const StyledPolitic = styled.div`
  display: flex;
  flex-direction: row;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 10px;
`;

export const StyledContaier = styled.div`
  max-width: 1200px;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  font-size: 14px;
  justify-content: space-between;
  @media (max-width: 800px) {
    font-size: 11px;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  justify-content: space-between;
`;

export const StyledRowIten = styled.div`
  flex: 0 0 auto;
  width: 50%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
  text-align: start;
`;
export const StyledRowIten2 = styled.div`
  display: flex;
  width: 40%;
  justify-content: space-around;
`;
export const StyledTranslate = styled.div`
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  width: 150px;
  padding-left: 0;
  padding-right: 0;
`;

export const StyledA1 = styled.a`
  font-size: 16px;
  line-height: 24px;
  color: rgb(117, 120, 147);
  outline: none;
  border: medium none;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
`;

export const StyledPrivacy = styled.div`
  max-width: 1200px;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
`;

export const StyledFoot = styled.div`
  max-width: 1200px;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
`;

export const StyledFooterInside = styled.div`
  display: flex;
  flex-direction: row;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledItens = styled.div`
  flex: 0 0 auto;
  width: 33.33333333%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);

  @media (max-width: 800px) {
    width: 400px;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
`;

export const StyledIten = styled.div`
  flex: 0 0 auto;
  width: 16.66666667%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);

  @media (max-width: 800px) {
    width: 400px;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
`;

export const StyledItentext = styled.div`
  width: 83.33333333%;
`;

export const StyledText = styled.p`
  display: flex;
  place-self: auto;
  width: auto;
  margin: 47px 0px 0px;
  color: #9599bb;
  font-size: 16px;
  line-height: 150%;
  font-family: "Poppins", sans-serif;

  @media (max-width: 800px) {
    margin: 47px 0px;
    text-align: center;
  }
`;
export const StyledP1 = styled.p`
  display: flex;
  place-self: auto;
  width: auto;
  margin: 0px 0px 30px;
  color: rgb(52, 63, 138);

  font-size: 22px;
  font-weight: 600;
  hyphens: manual;
  line-height: 33px;

  @media (max-width: 950px) {
    font-size: 18px;
  }

  @media (max-width: 800px) {
    text-align: center;
  }
`;

export const StyledList = styled.ul`
  padding-left: 0px;
  margin-bottom: 24px;
  list-style: disc;
  margin-top: 0;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
`;

export const StyledImg = styled.img`
  width: 91px;
  display: block;
  height: auto;
  border-radius: 2px;
  border-style: none;

  @media (max-width: 800px) {
    width: 110px;
  }
`;

export const StyledLi = styled.li`
  @media (max-width: 800px) {
    text-align: center;
  }
`;

export const StyledA = styled.a`
  color: #9599bb !important;
  text-decoration: none;
  cursor: pointer;
`;
