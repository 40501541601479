import { useSelector } from "react-redux";
import invest from "../../../images/invest.jpg";
import { BoxInfo, Card, Container, Image } from "./style";
import { FadeIn, FadeInUp } from "../../../Styles";

const Into = () => {
  let token = useSelector((state) => state).language.Language;

  return (
    <Container>
      <Card>
        <Image>
          <FadeIn duration="1.5s" delay="1s">
            <img
              src={invest}
              alt={token === "Portugues" ? "Investindo" : "Investing"}
            />
          </FadeIn>
        </Image>
        <BoxInfo>
          <FadeInUp duration="1.5s" delay="1s">
            <h2>Investindo na PixWave</h2>
            <p>
              Com você em todos os momentos. Conte com a PixWave para ter uma
              vida financeira cada vez mais segura, simples e eficaz.
            </p>
          </FadeInUp>
        </BoxInfo>
      </Card>
    </Container>
  );
};

export default Into;
