import atendiment from "../../../images/atendiment.jpg";
import { FadeInUp } from "../../../Styles";
import { BoxInfo, Card, Container } from "./style";
import { FaArrowRight } from "react-icons/fa";

const Atendiment = () => {
  return (
    <FadeInUp duration="1.5s" delay="1s">
      <Container>
        <Card>
          <BoxInfo>
            <h2>Atendimento em Português e Inglês</h2>
            <p>
              Sua comodidade na hora de investir é nossa prioridade. Por isso,
              conte com o nosso time de atendimento, escolha entre português ou
              inglês para o idioma da sua experiência.
            </p>
            <button>
              <a href="https://bank.pixwave.com.br/type-create-account">Abra sua conta</a>
              <FaArrowRight />
            </button>
          </BoxInfo>
          <img src={atendiment} alt="Homem conversando pelo telefone" />
        </Card>
      </Container>
    </FadeInUp>
  );
};

export default Atendiment;
