import styled from "styled-components";

export const Container = styled.div`
  flex: 1 0 auto;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;

  @media (max-width: 800px) {
    margin-top: 100px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  width: 1200px;
  border-radius: 20px;
  background-color: #9599bb;

  img {
    width: 50%;
    height: 443px;
    border-radius: 20px 0 0 20px;
  }

  @media (max-width: 800px) {
    width: 90%;
    flex-direction: column;

    img {
      width: 100%;
      height: 303px;
      border-radius: 20px 0 0 0;
    }
  }

  @media (max-width: 1024px) and (min-width: 801px) {
    flex-direction: column;
    width: 813px;
    border-radius: 20px;

    img {
      width: 100%;
      border-radius: 20px 0 0 0;
    }
  }
`;

export const BoxInfo = styled.div`
  background-color: #343f8a;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: start;
  font-family: "Poppins", sans-serif;
  padding: 50px 100px 50px 50px;
  border-radius: 0 20px 20px 0;

  h2 {
    font-size: 42px;
    font-weight: 600;
    line-height: 1.4em;
    letter-spacing: normal;
  }

  p {
    margin-top: 20px;
    font-size: 16px;
  }

  @media (max-width: 800px) {
    border-radius: 0 0 20px 0;
    align-items: center;
    text-align: center;
    padding: 50px 100px;

    h2 {
      font-size: 28px;
    }
    p {
      font-size: 14px;
    }
  }

  @media (max-width: 650px) {
    padding: 50px;
  }

  @media (max-width: 1024px) and (min-width: 801px) {
    flex-direction: column;
    width: 813px;
    border-radius: 20px;

    img {
      width: 100%;
      border-radius: 20px 0 0 0;
    }
  }

  @media (max-width: 1024px) and (min-width: 801px) {
    border-radius: 0 0 20px 0;
    align-items: center;
    text-align: center;
    padding: 50px 100px;
  }
`;
