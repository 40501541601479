/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import IconArrowDown from "../../../images/IconArrowDown";
import IconArrowLeft from "../../../images/IconArrowLeft";
import { ArrowSpan, Button, GetHeight, Response } from "./style";

const QuestComponent = ({ quests, widthSize, newId }) => {
  const [openQuest, setOpenQuest] = useState(false);
  const [questAnimation, setQuestAnimation] = useState(0);
  const [sizeHeight, setSizeHeight] = useState(0);
  const { quest, response } = quests;

  useEffect(() => {
    openQuest
      ? setTimeout(() => {
          questAnimation ? setOpenQuest(true) : setOpenQuest(false);
        }, 500)
      : questAnimation
      ? setOpenQuest(true)
      : setOpenQuest(false);

  }, [questAnimation]);

  const handleClick = () => {
    questAnimation ? setQuestAnimation(0) : setQuestAnimation(1);
    const newHeight = document.getElementById(newId).clientHeight;
    setSizeHeight(newId >= 4 && newId < 12 ? newHeight + 20 : newHeight);
  };

  return (
    <>
      <Button
        widthSize={widthSize}
        action={questAnimation}
        onClick={handleClick}
      >
        {quest}
        <ArrowSpan>
          {openQuest ? <IconArrowLeft /> : <IconArrowDown />}
        </ArrowSpan>
      </Button>
      <GetHeight id={newId}>{response}</GetHeight>
      {openQuest && (
        <Response size={sizeHeight} newId={newId} action={questAnimation}>
          {response}
        </Response>
      )}
    </>
  );
};

export default QuestComponent;
