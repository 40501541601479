import styled from "styled-components";

export const StyledSection = styled.section`
  background-color: white;
  background-size: cover;
  height: 450px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #f5f9ff;
  padding-top: 54px;
  @media (max-width: 800px) {
    height: auto;
  }
`;

export const StyledDiv = styled.div`
  text-align: center;
  display: block;

  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    flex-direction: column;
    padding: 20px 20px 50px 20px;
    width: 100%;
  }
`;

export const StyledDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;

  @media (max-width: 1220px) and (min-width: 801px) {
    width: 427px;
  }
  @media (max-width: 800px) {
    width: 100%;
    align-items: center;
  }
`;

export const StyledDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 40%;
  @media (max-width: 1220px) and (min-width: 801px) {
    width: 521px;
    height: auto;
  }
  @media (max-width: 800px) {
    flex-direction: row;
    height: auto;
    margin-top: 50px;
  }
`;

export const StyledH1 = styled.h1`
  width: 383px;
  height: 165px;
  text-align: left;
  margin-bottom: 25px;

  color: rgb(52, 63, 138);
  font-family: "Poppins", sans-serif;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.4em;

  @media (max-width: 800px) {
    font-size: 28px;
    width: 100%;
    margin: 0 0 20px 0;
    text-align: center;
    height: auto;
  }

  @media (max-width: 800px) {
    text-align: center;
  }
`;

export const StyledP = styled.p`
  width: 408px;
  height: 72px;

  text-align: left;

  color: rgb(52, 63, 138);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  hyphens: manual;
  line-height: 1.8em;
  margin-bottom: 14.4px;

  @media (max-width: 940px) {
    width: 340px;
  }

  @media (max-width: 800px) {
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
  }
`;

export const StyledP3 = styled.p`
  height: 26px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #9599bb;
`;

export const StyleViv6 = styled.div`
  display: flex;
`;

export const StyledDiv4 = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  cursor: pointer;
  color: rgb(52, 63, 138);
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #343f8a;
  margin: 0px 10px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 40px 40px 40px 40px;

  width: 243px;
  height: 217px;
  flex-direction: column;
  font-family: "Poppins";
  font-style: "Regular";
  font-size: 22px;
  line-height: 33px;
  line-height: 100%;
  align-items: center;
  vertical-align: top;

  @media (max-width: 800px) {
    width: 100%;
    height: 198px;
    margin-bottom: 20px;
  }
`;

export const StyledWallet = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #343f8a;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 40px 40px 40px 40px;

  width: 243px;
  height: 217px;

  color: #ffffff;
  flex-direction: column;
  font-family: "Poppins";
  font-style: "Regular";
  font-size: 22px;
  line-height: 33px;
  line-height: 100%;
  align-items: center;
  vertical-align: top;
  background: #343f8a;

  @media (max-width: 800px) {
    width: 100%;
    height: 198px;
  }
`;

export const StyledDiv5 = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  max-width: 750px;

  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    margin: 0;
    height: auto;
  }

  @media (max-width: 800px) and (min-width: 651px) {
    width: 90%;
  }
`;

export const Styledh2 = styled.p`
  flex-direction: column;
  font-size: 32px;
  line-height: 45px;
  font-weight: 400;
`;

export const StyledP2 = styled.p`
  align-items: center;
  vertical-align: top;

  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 600;
  hyphens: manual;
  line-height: 33px;
  margin-top: 12px;

  @media (max-width: 1250px) {
    font-size: 14px;
  }

  @media (max-width: 800px) {
    font-size: 16px;
  }
`;

export const StyledP4 = styled.p`
  height: 60px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 55px;
  text-align: center;
  color: #051c1d;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;

export const Styledlogo = styled.img`
  box-sizing: border-box;
  width: 55px;
  height: 55px;

  background: url(8-03);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 4px;
`;

export const StyledMais = styled.img`
  width: 13px;
  height: 13px;
  margin: 7px 0 0 7px;
`;

export const StyledButton = styled.button`
  width: 210px;
  height: 58px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  margin-top: 5px;

  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border: 2px solid #343f8a;
  border-radius: 10px 10px 10px 10px;
  background-color: #343f8a;
  padding: 20px 30px 20px 30px;
  margin: 20px 0px 0px 0px;

  display: flex;
  align-items: center;

  p {
    margin-right: 5px;
  }

  &:hover {
    background-color: #fff;
    color: #343f8a;
  }
`;
