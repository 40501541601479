import styled from "styled-components";
import mainImg from "../../../images/introBackGround.png";
import mainImgs from "../../../images/bg_overlay3.png";
export const StyledSection = styled.section`
  background-image: url(${mainImg});
  background-color: white;
  background-size: cover;
  height: 900px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    height: auto;
  }

  @media (max-width: 650px) {
    height: 1475px;
  }
`;
export const Opacity = styled.div`
  background-image: url(${mainImgs});
  background-position: center left;
  background-size: cover;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
export const StyledDiv = styled.div`
  text-align: center;
  display: block;

  width: 1140px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start !important;
    margin-top: 20px;
  }

  @media (max-width: 1100px) {
    width: 100%;
    justify-content: center;
  }
`;

export const StyledDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  @media (max-width: 650px) {
    align-items: center;
    margin-bottom: 60px;
    width: 50%;
  }

  @media (max-width: 800px) {
    width: 90%;
  }
  @media (max-width: 1100px) and (min-width: 801px) {
    width: 40%;
  }
`;

export const StyledDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 570px;
  height: 483px;
  @media (max-width: 1220px) {
    width: 474px;
  }

  @media (max-width: 650px) {
    margin-top: 248px;
    align-items: center;
    width: 100%;
  }
  @media (max-width: 800px) and (min-width: 651px) {
    width: 90%;
    height: 100%;
  }
`;

export const StyledH1 = styled.h1`
  width: 500px;
  height: auto;
  text-align: left;

  color: rgb(52, 63, 138);
  font-family: "Poppins", sans-serif;
  font-size: 42px;
  font-weight: 600;
  hyphens: manual;
  line-height: 1.4em;

  @media (max-width: 1435px) {
    width: 375px;
    font-size: 35px;
  }

  @media (max-width: 650px) {
    text-align: center;
    margin: 30px 0;
    width: 423px;
  }

  @media (max-width: 900px) {
    text-align: center;
    width: 100%;
    font-size: 26px;
    margin-top: 30px;
  }
`;

export const StyledP = styled.p`
  width: 500px;
  height: 51px;
  text-align: left;

  color: rgb(52, 63, 138);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  hyphens: manual;
  line-height: 25.2px;
  margin-top: 10px;

  @media (max-width: 1400px) {
    width: 370px;
    font-size: 16px;
  }

  @media (max-width: 650px) {
    text-align: center;
    width: 60%;
    height: 100px;
  }
  @media (max-width: 900px) {
    width: 100%;
    text-align: center;
  }
`;

export const StyledDiv4 = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  border: 2px solid #343f8a;
  border-radius: 10px;
  cursor: pointer;
  width: 275px;
  height: 213px;
  flex-direction: column;
  font-family: "Poppins";
  font-style: "Regular";
  font-size: 22px;
  line-height: 33px;
  line-height: 100%;
  align-items: center;
  vertical-align: top;

  color: rgb(39, 52, 138);

  @media (max-width: 1220px) and (min-width: 801px) {
    width: 227px;
    height: 201px;
  }
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 20px;
    height: 190px;
  }

  @media (max-width: 650px) {
    margin-top: 20px;
    height: 233px;
  }
`;

export const StyledChange = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  border: 2px solid #343f8a;
  border-radius: 10px;
  cursor: pointer;

  width: 275px;
  height: 213px;
  color: #ffffff;
  flex-direction: column;
  font-family: "Poppins";
  font-style: "Regular";
  font-size: 22px;
  line-height: 33px;
  line-height: 100%;
  align-items: center;
  vertical-align: top;

  background: #343f8a;

  @media (max-width: 1220px) and (min-width: 801px) {
    width: 227px;
    height: 201px;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 190px;
    margin-bottom: 20px;
  }

  @media (max-width: 650px) {
    margin-top: 20px;
    height: 233px;
  }
`;

export const StyledDiv5 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  width: 98%;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
  }
`;

export const Styledh2 = styled.p`
  flex-direction: column;
  font-size: 32px;
  line-height: 45px;
  font-weight: 400;
`;

export const StyledP2 = styled.p`
  align-items: center;
  vertical-align: top;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 600;
  hyphens: manual;
  line-height: 33px;
  width: 100%;
  margin-top: 15px;

  @media (max-width: 1100px) {
    font-size: 17px;
  }
`;

export const Styledlogo = styled.img`
  box-sizing: border-box;
  width: 50px;
  height: 40px;
  background: url(8-03);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 4px;
`;
