import styled from "styled-components";

export const Container = styled.section`
  text-align: center;
  position: relative;
  height: 847px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  background-color: #343f8a;

  @media (max-width: 650px) {
    height: 1751px;
    padding: 20px;
  }
  @media (max-width: 800px) and (min-width: 651px) {
    height: 2078px;
    align-items: center;
    justify-content: flex-start;
    padding: 60px 40px;
  }
  @media (max-width: 1050px) and (min-width: 801px) {
    height: 687px;
  }
`;

export const Header = styled.h2`
  color: #ffffff;
  font-size: 42px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  line-height: 1.4em;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    font-size: 28px;
  }
`;

export const Text = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: white;

  @media (max-width: 800px) {
    margin-bottom: 30px;
    width: 100%;
  }
`;

export const Box = styled.div`
  height: 474px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) => props.padding};

  @media (max-width: 800px) {
    padding: 0;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
  margin-bottom: 30px;

  h3 {
    font-family: "Poppins";
    font-size: 22px;
    font-weight: 600;
    line-height: 1.5em;
    color: #ffffff;
    margin-left: 25px;
  }

  div {
    border-radius: 10px;
    border: 2px solid white;
    width: 44px;
    height: 44px;
    margin-left: 25px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    font-weight: 900;
    font-size: 20px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    h3 {
      margin: 0;
    }
    div {
      margin: 30px 0;
    }
  }

  @media (max-width: 1050px) and (min-width: 801px) {
    h3 {
      margin: 0 20px;
    }
    div {
      margin: 0;
    }
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  @media (max-width: 1050px) and (min-width: 801px) {
    flex-direction: row-reverse;
  }
`;

export const Image = styled.img`
  width: 380px;
  height: 558px;
  border-radius: 0px;

  @media (max-width: 650px) {
    width: 304px;
    height: 446px;
    margin: 20px;
  }

  @media (max-width: 800px) and (min-width: 651px) {
    margin: 20px 0;
    width: 570px;
    height: 836px;
  }

  @media (max-width: 1050px) and (min-width: 801px) {
    margin: 0;
    width: 263px;
    height: 385px;
  }
`;
