import styled from "styled-components";

export const BoxVideo = styled.iframe`
  height: 407px;
  width: 724px;
  min-height: 407px;
  border: 10px solid #ffffff;
  border-radius: 5px;
  position: relative;

  @media (max-width: 800px) and (min-width: 651px) {
    min-height: 306px;
  }

  @media (max-width: 650px) {
    width: 300px;
    height: 184px;
    min-height: 184px;
  }

  @media (max-width: 799px) and (min-width: 651px) {
    width: 550px;
    height: 350px;
  }
`;
