import Router from './Routes/routes';

function App() {
  return (

    <Router />

  );
}

export default App;
