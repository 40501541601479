import frontImg from "../../../images/phones.png";
import { StyledDiv, Container, Header, Text, Box, Item, Image } from "./style";
import {
  FaTasks,
  FaShieldAlt,
  FaShareAlt,
  FaSun,
  FaThumbsUp,
  FaUserTie,
} from "react-icons/fa";
import { FadeInUp } from "../../../Styles";

function PhoneComponent() {
  return (
    <Container>
      <FadeInUp duration="1.5s" delay="1s">
        <Header>Por que escolher a PixWave?</Header>
        <Text>
          A conta da PixWave não tem tarifas de manutenção e cobranças abusivas.
        </Text>

        <StyledDiv>
          <Box padding="0 30px 0 0" fle>
            <Item justifyContent="flex-end">
              <h3>Processo Simples </h3>
              <div margin="0 25px 0 0">
                <FaThumbsUp />
              </div>
            </Item>
            <Item justifyContent="flex-end">
              <h3>Banco Confiável </h3>
              <div margin="0 25px 0 0">
                <FaUserTie />
              </div>
            </Item>
            <Item justifyContent="flex-end">
              <h3>Boa Avaliação </h3>

              <div margin="0 25px 0 0">
                <FaSun />
              </div>
            </Item>
          </Box>
          <Image src={frontImg} />
          <Box padding="0 0 0 30px">
            <Item>
              <div>
                <FaTasks />
              </div>
              <h3>Transferências Gratuitas</h3>
            </Item>
            <Item>
              <div>
                <FaShieldAlt />
              </div>
              <h3>Sistema Seguro</h3>
            </Item>
            <Item>
              <div>
                <FaShareAlt />
              </div>
              <h3>Liberdade Financeira</h3>
            </Item>
          </Box>
        </StyledDiv>
      </FadeInUp>
    </Container>
  );
}

export default PhoneComponent;
