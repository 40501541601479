import HeaderComponent from "../Home/Header";
import FooterComponent from "../Home/Footer";
import { StyledApp } from "./style";
import MainComponent from "./Main";
import IntoAboutUs from "./Into";
import CountInvest from "./CountInvest";
import Suport from "./Suport";
import Structure from "./Structure";
import ReadyComponent from "../Home/Ready";
import { useEffect, useState } from "react";
import SideModal from "../SideModal";

function AboutUsComponent() {
  const [showModal, setShowModal] = useState(false);
  const [modalAnimation, setModalAnimation] = useState(0);

  useEffect(() => {
    showModal === true
      ? setTimeout(() => {
          modalAnimation ? setShowModal(true) : setShowModal(false);
        }, 490)
      : modalAnimation
      ? setShowModal(true)
      : setShowModal(false);
  }, [modalAnimation]);
  return (
    <StyledApp>
      {showModal && (
        <SideModal
          setModalAnimation={setModalAnimation}
          modalAnimation={modalAnimation}
        />
      )}
      <HeaderComponent
        setModalAnimation={setModalAnimation}
        modalAnimation={modalAnimation}
      />
      <MainComponent text="Sobre a Empresa" home="Home" />
      <IntoAboutUs />
      <CountInvest />
      <Suport />
      <Structure />
      <ReadyComponent />
      <FooterComponent />
    </StyledApp>
  );
}

export default AboutUsComponent;
