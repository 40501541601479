import { FadeInUp } from "../../../Styles";
import logo1 from "../../../images/cardIcon.png";
import logo3 from "../../../images/handshake.png";
import { FaUserFriends } from "react-icons/fa";
import {
  StyledChange,
  StyledDiv,
  StyledDiv2,
  StyledDiv3,
  StyledDiv4,
  StyledDiv5,
  StyledH1,
  StyledP,
  StyledP2,
  StyledSection,
  Styledlogo,
} from "./style";

function IntroComponent() {
  return (
    <FadeInUp duration="1.5s" delay="1s">
      <StyledSection>
        <StyledDiv>
          <StyledDiv2>
            <StyledH1>A liberdade que você procura ao seu alcance.</StyledH1>
            <StyledP>
              Fornecemos e simplificamos tudo o que você precisa para levar uma
              vida financeira global sem burocracias.
            </StyledP>
          </StyledDiv2>

          <StyledDiv3>
            <StyledDiv5>
              <StyledDiv4>
                <Styledlogo src={logo1} />
                <StyledP2>Conta 100% digital</StyledP2>
              </StyledDiv4>
              <StyledChange>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44px"
                  height="44px"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      stroke="#ffffff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 17h12M4 17l3.5-3.5M4 17l3.5 3.5M7 7h13m0 0l-3.5-3.5M20 7l-3.5 3.5"
                    ></path>
                  </g>
                </svg>
                <StyledP2>Câmbio/Exchange</StyledP2>
              </StyledChange>
            </StyledDiv5>
            <StyledDiv5>
              <StyledDiv4>
                <FaUserFriends fontSize="40px" />
                <StyledP2>Clientes</StyledP2>
              </StyledDiv4>
              <StyledDiv4>
                <Styledlogo src={logo3} />
                <StyledP2>PW Partner</StyledP2>
              </StyledDiv4>
            </StyledDiv5>
          </StyledDiv3>
        </StyledDiv>
      </StyledSection>
    </FadeInUp>
  );
}

export default IntroComponent;
