import HeaderComponent from "../Home/Header";
import FooterComponent from "../Home/Footer";
import MainComponent from "./Main";
import ReadyComponent from "../Home/Ready";
import PhoneComponent from "./Phone";
import ExchangeComponent from "./Exchange";
import DigitalComponent from "./Digital";
import PixComponent from "./Pix";
import PartnerComponent from "./Partner";
import { StyledApp } from "./style";
import Benefits from "./Benefits";
import WalletDigital from "./WalletDigital";
import { useState, useEffect } from "react";
import SideModal from "../SideModal";

function ServiceComponent() {

  const [showModal, setShowModal] = useState(false);
  const [modalAnimation, setModalAnimation] = useState(0);

  useEffect(() => {
    showModal === true
      ? setTimeout(() => {
          modalAnimation ? setShowModal(true) : setShowModal(false);
        }, 490)
      : modalAnimation
      ? setShowModal(true)
      : setShowModal(false);
  }, [modalAnimation]);

  return (
    <StyledApp>
      {showModal && (
        <SideModal
          setModalAnimation={setModalAnimation}
          modalAnimation={modalAnimation}
        />
      )}
      <HeaderComponent
        setModalAnimation={setModalAnimation}
        modalAnimation={modalAnimation}
      />
      <MainComponent />
      <Benefits />

      <PhoneComponent />
      <DigitalComponent />
      <ExchangeComponent />
      <WalletDigital />
      <PixComponent />
      <PartnerComponent />
      <ReadyComponent />
      <FooterComponent />
    </StyledApp>
  );
}

export default ServiceComponent;
