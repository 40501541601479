import styled from "styled-components";

export const StyledSection = styled.section`
  background-color: #27348a;
  background-size: cover;
  height: 525px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    height: 365apx;
  }
`;

export const StyledDiv = styled.div`
  text-align: center;
  display: block;
  width: 70%;
  height: 100%;
  width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1350px) {
    width: 880px;
  }

  @media (max-width: 950px) {
    align-items: center;
  }
  @media (max-width: 800px) {
    width: 0;
  }
`;

export const StyledBr = styled.br`
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const StyledDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  @media (max-width: 800px) {
    align-items: center;
    width: 100%;
  }
`;

export const StyledImg = styled.img`
  width: 410px;
  height: 638px;
  border-radius: 4px;
  position: absolute;
  bottom: 0px;

  @media (max-width: 900px) {
  width: 399px;
  height: 507px;
  }
`;

export const StyledDiv6 = styled.div`
  width: 48%;
`;

export const StyledDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-itens: center;
  text-align: center;
  width: 50%;
  height: 525px;
  background-size: cover;

  // @media (max-width: 1200px) {
  //   align-items: center;
  // }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const StyledH1 = styled.h1`
  width: 520px;
  height: 95px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 46px;
  text-align: left;

  color: #ffffff;

  // @media (max-width: 1200px) {
  //   align-items: center;
  //   font-size: 30px;
  //   width: unset;
  // }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const StyledP = styled.p`
  text-align: left;
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  margin-top: 5px;
  margin-bottom: 14px;
  width: 520px;
  height: 52px;

  // @media (max-width: 1200px) {
  //   width: 300px;
  //   text-align: center;
  // }

  @media (max-width: 1070px) {
    width: 420px;
  }
`;

export const StyledButton = styled.div`
  color: #27348a;
  background-color: #fff;
  width: 192px;
  height: 58px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  text-align: center;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #ffffff;
  border-radius: 10px 10px 10px 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    background-color: #27348a;
    color: #fff;
    border-color: #fff;
  }

  margin-top: 10px;
  @media (max-width: 800px) {
    display: none;
  }
`;
