import styled from "styled-components";

export const Container = styled.div`
  flex: 1 0 auto;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  margin-bottom: ${(props) => props.marginBottom};

  @media (max-width: 800px) {
    margin-top: 100px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  width: 1200px;
  border-radius: 20px 0 0 20px;
  background-color: #f5f9ff;

  img {
    width: 700px;
    height: 500px;
  }

  @media (max-width: 800px) {
    width: 90%;
    border-radius: 20px;

    img {
      display: none;
    }
  }

  @media (max-width: 1024px) and (min-width: 801px) {
    flex-direction: column-reverse;
    width: 813px;
    border-radius: 20px;

    img {
      width: 100%;
    }
  }
`;

export const BoxInfo = styled.div`
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: start;
  font-family: "Poppins", sans-serif;
  padding: 50px 50px 50px 50px;

  h2 {
    color: #343f8a;
    font-size: 42px;
    font-weight: 600;
    line-height: 1.4em;
    letter-spacing: normal;
  }

  p {
    font-size: 16px;
    color: #9599bb;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    background-color: #343f8a;
    color: #ffffff;
    padding: 20px 30px;
    border-radius: 10px;
    border: #343f8a solid 1px;
    margin-top: 40px;
    cursor: pointer;
    p {
      margin-right: 8px;
      color: #ffffff;
    }
    &:hover {
      background-color: #ffffff;
      color: #343f8a;
      border-color: #343f8a;
      p {
        color: #343f8a;
      }
    }
  }

  @media (max-width: 800px) {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;

    h2 {
      font-size: 28px;
      margin-bottom: 10px;
    }

    p,
    button {
      font-size: 14px;
    }
  }

  @media (max-width: 1024px) and (min-width: 801px) {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`;
