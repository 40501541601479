import styled from "styled-components";
import mobilecard from "../../../images/mobilecard.png";
import introImg from "../../../images/serviceImg.png";

export const StyledSection = styled.section`
  background-image: url(${introImg});
  background-size: cover;
  background-position: center;
  height: 408px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;

  @media (max-width: 1000px) {
    background-image: url(${mobilecard});
    background-position: bottom;
    height: 350px;
  }
`;

export const StyledBackgorund = styled.div`
  background-color: rgba(39, 52, 138, 0.7);
  width: 100%;

  display: flex;
  justify-content: center;
`;

export const StyledDiv = styled.div`
  text-align: center;
  display: block;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const StyledImg = styled.img`
  width: 447px;
  height: 480px;
  border-radius: 4px;
  position: absolute;
  bottom: 0px;
`;

export const StyledDiv3 = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 329px;
  height: 120px;
  background-size: cover;
`;

export const Header = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 44px;
  font-weight: 600;
  hyphens: manual;
  line-height: 58.8px;
  text-align: center;
  color: #ffffff;
`;

export const StyledP = styled.p`
  margin-top: 5px;
  text-align: center;

  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  hyphens: manual;
  line-height: 25.2px;
  color: white;

  display: flex;
  align-items: center;

  svg {
    color: #9082FF;
    margin-left: 5px;
    margin-right: 5px;
    width: 31.5px;
    height: 21px;
}

`;

export const StyledSeta = styled.img`
  width: 21px;
  height: 17px;
`;
