import styled from "styled-components";
import circleComplete from "../../../images/circleComplete.png";
import circle from "../../../images/circle.png";

export const Container = styled.div`
  flex: 1 0 auto;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;

  @media (max-width: 800px) {
    margin-top: 40px;
  }

  @media (max-width: 1220px) and (min-width: 651px) {
    margin-top: 80px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  width: 1200px;
  background-color: #f8f8f8;
  border-radius: 0 20px 20px 0;
  height: 529px;

  @media (max-width: 800px) {
    width: 90%;
    flex-direction: column;
    border-radius: 0;
    height: auto;
  }

  @media (max-width: 1220px) and (min-width: 801px) {
    width: 813px;
    height: auto;

    flex-direction: column;
    border-radius: 0;
  }
`;

export const Image = styled.div`
  opacity: 0.8;

  background-image: url(${circleComplete});
  background-size: cover;
  background-position: center center;

  img {
    width: 793px;
    height: 100%;
  }

  @media (max-width: 650px) {
    background-image: url(${circle});

    height: 300px;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      border-radius: 0;
      width: 100%;
      height: 260px;
    }
  }

  @media (max-width: 800px) and (min-width: 651px) {
    background-image: url(${circle});

    height: 462px;
    width: 100%;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 422px;
    }
  }

  @media (max-width: 1220px) and (min-width: 801px) {
    img {
      width: 100%;
    }
  }
`;

export const BoxInfo = styled.div`
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: start;
  font-family: "Poppins", sans-serif;
  padding-left: 50px;

  h2 {
    color: #343f8a;
    font-size: 42px;
    font-weight: 600;
    line-height: 1.4em;
    letter-spacing: normal;
    width: 362px;
    height: 118px;
  }

  p {
    font-size: 16px;
    color: #9599bb;
    width: 324px;
  }

  @media (max-width: 650px) {
    height: 200px;
    text-align: center;
    padding: 20px;
    border-radius: 0;

    h2 {
      font-size: 28px;
      width: 100%;
      height: auto;
    }
    p {
      font-size: 14px;
      color: #9599bb;
      width: auto;
    }
  }

  @media (max-width: 800px) {
    text-align: center;
    padding: 20px;
    width: 100%;
    height: 170px;
    h2 {
      font-size: 28px;
      width: 100%;
      height: auto;
    }
    p {
      font-size: 14px;
      color: #9599bb;
      width: auto;
    }
  }

  @media (max-width: 1220px) and (min-width: 801px) {
    height: 170px;
    text-align: center;
    h2 {
      font-size: 33px;
      width: 100%;
      height: auto;
    }
    p {
      font-size: 14px;
      color: #9599bb;
      width: auto;
    }
  }
`;
