import { Container, Header, Image, InfoText, Opacity, TextArea } from "./style";
import peopleDiversity from "../../../images/digital.jpg";
import { FadeIn, FadeInUp } from "../../../Styles";
const DigitalComponent = () => {
  return (
    <Container>
      <Opacity></Opacity>
      <InfoText>
        <FadeInUp duration="1.5s" delay="1s">
          <Header>Conta 100% Digital</Header>
        </FadeInUp>
        <TextArea>
          Tudo que você precisa de forma 100% digital. Sua conta também te dá
          acessos a serviços e recursos exclusivos.
        </TextArea>
      </InfoText>

      <FadeIn duration="1.5s" delay="1s">
        <Image src={peopleDiversity} />
      </FadeIn>
    </Container>
  );
};

export default DigitalComponent;
