import {
  StyledHead,
  StyledHeader,
  StyledIten,
  StyledItens,
  StyledMenu,
  StyledP1,
  StyledP2,
  StyledImg,
  StyledImgMenu,
  StyledModal
} from "./style";

import { FiMenu } from "react-icons/fi";
import { RiArrowDownSLine } from "react-icons/ri";


import mainLogo from "../../../images/headerLogo.png";
import menuLogo from "../../../images/menu.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const HeaderComponent = ({ setModalAnimation, modalAnimation }) => {
  const [modal, setModal] = useState(false);
  const navigate = useNavigate()

  const changeModal = (modal) => {
    setModal(!modal)
  }

  return (
    <StyledHeader>
      <StyledHead>
        <StyledItens>
          <a href="/">
            <StyledImg src={mainLogo} alt="pixwave" width="130" />
          </a>
          <StyledMenu>
            <div>
              <StyledIten>
                <StyledP1 href="/service">Para Você</StyledP1>
              </StyledIten>
            </div>
            <div>
              <StyledIten>
                <StyledP1 href="/business">Para Empresas</StyledP1>
              </StyledIten>
            </div>
            <div>
              <StyledIten onClick={() => changeModal(modal)}>
                <StyledP1 >Ajuda</StyledP1>
                <StyledModal onClick={() => navigate("/faq")}>FAQ</StyledModal >
                <RiArrowDownSLine />
              </StyledIten>
            </div>
          </StyledMenu>
        </StyledItens>

        <StyledItens>
          <div>
            <StyledIten>
              <StyledP1><a href="https://bank.pixwave.com.br/type-create-account">Abra sua conta</a></StyledP1>
            </StyledIten>
          </div>
          <div>
            <StyledIten>
              <StyledP2><a href="https://bank.pixwave.com.br/signin">Entrar</a></StyledP2>
            </StyledIten>
            <StyledImgMenu
              onClick={() =>
                modalAnimation ? setModalAnimation(0) : setModalAnimation(1)
              }
              src={menuLogo}
            >
              <FiMenu />
            </StyledImgMenu>
          </div>
        </StyledItens>
      </StyledHead>
    </StyledHeader>
  );
};

export default HeaderComponent;
