import styled from "styled-components";

export const Container = styled.section`
  flex: 1 0 auto;

  position: relative;
  height: 350px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 100px 0;

  @media (max-width: 650px) {
    flex-direction: column;
    height: 450px;
  }
`;

export const BoxImg = styled.img`
  width: 45%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  @media (max-width: 650px) {
    width: 100%;
    height: 60%;
  }

  @media (max-width: 800px) and (min-width: 651px) {
    width: 50%;
  }
  @media (max-width: 1200px) and (min-width: 801px) {
    width: 50%;
  }
`;

export const BoxText = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  width: 65%;
  background-color: #343f8a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 70px;
  padding: 100px;
  div {
    width: 100%;
    justify-content: flex-start;
    text-align: start;
  }
  h2 {
    color: white;
    font-size: 42px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    line-height: 1.4em;
  }

  p {
    line-height: 1.8em;
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }

  @media (max-width: 800px) {
    div {
      text-align: center;
    }
    h2 {
      font-size: 28px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 650px) {
    width: 100%;
    height: 40%;
    padding: 20px;
    bottom: 0;
    border-radius: 0;
  }
  @media (max-width: 800px) and (min-width: 651px) {
    width: 60%;
    padding: 30px;
  }
  @media (max-width: 1200px) and (min-width: 801px) {
    width: 60%;
  }
`;
