import { FaArrowRight } from "react-icons/fa";
import frontImg from "../../../images/cards.png";
import {
  BoxImg,
  BoxText,
  Button,
  Card,
  Container,
  ContainerOpacity,
  Item,
} from "./style";
import check from "../../../images/check.png";
import { FadeIn, FadeInUp } from "../../../Styles";

const Benefits = () => {
  return (
    <Container>
      <ContainerOpacity></ContainerOpacity>
      <Card>
        <BoxImg>
          <div>
            <FadeIn duration="1.5s" delay="1s">
              <img src={frontImg} alt="Cartões PixWave" />
            </FadeIn>
          </div>
        </BoxImg>
        <BoxText>
          <FadeInUp duration="1.5s" delay="1s">
            <h2>As vantagens de ser Global com a PixWave</h2>
            <p>
              Quem é PixWave tem uma vida financeira de outro mundo! Além de ter tudo que precisa de forma 100% digital, na PixWave você envia, recebe e converte seus ativos digitais de forma instantânea e com a melhor cotação do mercado. Envie e receba dinheiro em segundos via Pix,  e tenha acesso a produtos que te oferecem serviços, ofertas e benefícios exclusivos com a PW Partner.
            </p>
            <div>
              <Item>
                <img src={check} alt="check" />
                <p>Conta Digital</p>
              </Item>
              <Item>
                <img src={check} alt="check" />

                <p>Ativos Digitais</p>
              </Item>
              <Item>
                <img src={check} alt="check" />
                <p>Câmbio/Exchange</p>
              </Item>
              <Item>
                <img src={check} alt="check" />
                <p>Pix</p>
              </Item>
              <Item>
                <img src={check} alt="check" />

                <p>PW Partner</p>
              </Item>
            </div>
            <Button>
              <button>
                <a href="https://bank.pixwave.com.br/type-create-account">Abra sua conta</a>
                <FaArrowRight />
              </button>
            </Button>
          </FadeInUp>
        </BoxText>
      </Card>
    </Container>
  );
};

export default Benefits;
