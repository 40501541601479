import styled from "styled-components";
import left from "../../../images/leftPurple.png";

export const Container = styled.section`
  min-height: 500px;
  padding: 100px 0;

  background-image: url(${left});
  background-position: center;
  background-color: white;
  background-size: cover;

  text-align: center;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 1024px) {
    align-items: center;
    justify-content: start;
    flex-direction: column;
  }

	@media (max-width: 600px) {
		padding: 50px 0;
  }

	@media (max-width: 400px) {
		padding: 20px 0;
  }

	@media (max-width: 350px) {
		padding: 15px 0;
  }
`;

export const Header = styled.h2`
  width: 520px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 1.4em;
  text-align: left;
  color: #343f8a;

  @media (max-width: 1024px) {
    text-align: center;
  }
`;

export const Text = styled.p`
  max-width: 520px;

  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.8em;
  color: #9599bb;
  text-align: left;

  @media (max-width: 1024px) {
    text-align: center;
    max-width: 90vw;
  }
`;

export const Button = styled.button`
  box-sizing: border-box;

  width: 208px;
  height: 59px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  background: #343f8a;

  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;

  border: 2px solid #343f8a;
  border-radius: 10px;
  margin-top: 30px;
  cursor: pointer;

  span {
    font-size: 2.5rem;
    margin: 0 0 13px 5px;
    line-height: 20px;
  }

  &:hover {
    background-color: #fff;
    color: #343f8a;
  }
`;

export const StyledP2 = styled.p`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;

  color: #ffffff;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justify};
  min-height: ${(props) => (props.size ? "auto" : "300px")};
  width: ${(props) => props.size && "47vw"};

  @media (max-width: 1024px) {
    align-items: center;
    width: 100vw;
  }
`;
