import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home, Business, Service, Faq, Investment, AboutUs, Privacy } from "../Components";


const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/business" element={<Business />} />
      <Route path="/service" element={<Service />} />
      <Route path="/investment" element={<Investment />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/politicas" element={<Privacy />} />
      
    </Routes>

  );
};

export default Router;
