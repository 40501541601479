import { FadeInUp } from "../../../Styles";
import { BoxInfo, Card, Container, Heading, Text } from "./style";

export const Structure = () => {

  return (
    <Container marginBottom="150px">
      <Card>
          <BoxInfo>
            <FadeInUp duration="1.5s" delay="1s">
              <Heading>Nossa estrutura</Heading>
              <Text>
                A estrutura da PixWave é composta por instituições financeiras
                no Brasil e exterior que, em conjunto, oferecem uma experiência
                completa, regulada e segura.
              </Text>
            </FadeInUp>
          </BoxInfo>
        <div />
      </Card>
    </Container>
  );
};

export default Structure;
