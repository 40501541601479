import {
  Card,
  Container,
  Header,
  Image,
  InfoText,
  Opacity,
  TextArea,
} from "./style";
import { FadeIn, FadeInUp } from "../../../Styles";

const PixComponent = () => {
  return (
    <Container>
      <Opacity></Opacity>
      <Card>
        <FadeInUp duration="1.5s" delay="1s">
          <InfoText>
            <Header>Pix</Header>
            <TextArea>
              <li>
                Receba suas transferências em segundos. Faça pagamentos
                instantâneos e gratuitos na sua conta digital!
              </li>
            </TextArea>
          </InfoText>
        </FadeInUp>

        <FadeIn duration="1.5s" delay="1s">
          <Image />
        </FadeIn>
      </Card>
    </Container>
  );
};

export default PixComponent;
