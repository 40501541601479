import {
  StyledSection,
  StyledH1,
  StyledP,
  StyledHead,
  StyledDiv,
  StyledDiv1,
  StyledSection2,
  Styleddiv2,
  Styleddiv3,
  StyledH2,
  StyledP2,
  Box,
} from "./style";
import QuestComponent from "../Quest";
import { FadeInUp } from "../../../Styles";

const QuestsComponent = () => {
  const questsBox1 = [
    {
      quest: "Como falar com a PixWave?",
      response:
        "Nosso atendimento está disponível de segunda à sexta-feira das 10:00 às 19:00 horas (horário de Brasília) através de chat, telefone e e-mail.",
    },
    {
      quest: "O que é câmbio/exchange?",
      response:
        "Transfira, receba e e converta seus ativos digitais instantaneamente em um sistema integrado de wallets.",
    },
    {
      quest: "Quem pode ter a conta PixWave?",
      response:
        "A conta digital PixWave foi feita para todos. Não é preciso passar por uma análise de crédito, você abre sua conta direto em nossa plataforma, tudo rápido e sem burocracia.",
    },
    {
      quest: "Posso fazer saques com minha conta PixWave?",
      response:
        "Nossos clientes podem sacar com a função débito em qualquer caixa eletrônico da rede Banco 24 horas.",
    },
  ];

  const questsBox2 = [
    {
      quest: "A PixWave faz operação de câmbio com quais moedas",
      response:
        "A PixWave faz conversão com mais de 100 moedas de todo o mundo.",
    },
    {
      quest: "Quais são as tarifas da conta PixWave?",
      response:
        "Somos contra cobrar tarifas. Como usamos canais 100% digitais conseguimos repassar apenas benefícios e oportunidades, nunca gastos.",
    },
    {
      quest: "Como realizar câmbio para compra de ativos digitais?",
      response:
        "Deverá ser feito o câmbio para a conta investimentos, informando o objetivo de investir em ativos digitais.",
    },
    {
      quest: "Como passo um feedback ou sugestão?",
      response:
        "Caso tenha feedbacks ou sugestões, envie um e-mail para suporte@pixwave.com e nosso time irá repassá-los para as equipes responsáveis.",
    },
  ];

  const questsBox3 = [...questsBox1, ...questsBox2];

  return (
    <>
      <StyledSection>
        <StyledDiv1>
          <StyledHead>
            <StyledH1>FAQ</StyledH1>
            <StyledP>Mais perguntas frequentes:</StyledP>
          </StyledHead>
          <FadeInUp size={true} duration="1.5s" delay="1s">
            <StyledDiv size={true}>
              <Box
                size={true}
                justify="space-around"
                alignItems="center"
                width="45vw"
              >
                {questsBox1.map((item, index) => (
                  <QuestComponent
                    key={index}
                    quests={item}
                    newId={index + 4}
                    widthSize={true}
                  />
                ))}
              </Box>
              <Box
                size={true}
                justify="space-around"
                alignItems="center"
                width="45vw"
              >
                {questsBox2.map((item, index) => (
                  <QuestComponent
                    key={index}
                    quests={item}
                    newId={index + 8}
                    widthSize={true}
                  />
                ))}
              </Box>
            </StyledDiv>
          </FadeInUp>
        </StyledDiv1>
      </StyledSection>

      <StyledSection2>
        <Styleddiv2>
          <StyledH2>FAQ</StyledH2>
          <StyledP2>Mais perguntas frequentes:</StyledP2>
        </Styleddiv2>
        <Styleddiv3>
          {questsBox3.map((item, index) => (
            <QuestComponent
              size={true}
              key={index}
              newId={index + 12}
              quests={item}
            />
          ))}
        </Styleddiv3>
      </StyledSection2>
    </>
  );
};

export default QuestsComponent;
