import styled from "styled-components";
import circle from "../../../images/circleComplete.png";

export const Container = styled.div`
  flex: 1 0 auto;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: ${(props) => props.marginBottom};
  height: 733px;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin: 70px 0;
  }
`;

export const BoxImg = styled.div`
  height: 533px;
  width: 570px;
  background-image: url(${circle});
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 570px;
    height: 457.5px;
  }

  @media (max-width: 650px) {
    width: 90%;
    height: 311px;

    img {
      width: 338px;
      height: 271px;
    }
  }
`;

export const BoxInfo = styled.div`
  width: 570px;
  height: 533px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 70px;
  padding-left: 40px;

  h2 {
    color: #343f8a;
    font-size: 42px;
    font-weight: 600;
    line-height: 1.4em;
    letter-spacing: normal;
  }

  p {
    margin-top: 20px;
    font-size: 14px;
    color: #9599bb;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    background-color: #343f8a;
    color: #ffffff;
    padding: 20px 30px;
    border-radius: 10px;
    border: #343f8a solid 1px;
    margin-top: 40px;
    cursor: pointer;
    p {
      color: #ffffff;
      margin-top: 0;
      margin-right: 8px;
    }
    &:hover {
      background-color: #ffffff;
      color: #343f8a;
      border-color: #343f8a;
      p {
        color: #343f8a;
      }
    }
  }

  @media (max-width: 1220px) {
    padding: 0;
    width: 90%;
    h2 {
      font-size: 33px;
    }
    p {
      margin-top: 10px;
    }
    button {
      margin-top: 20px;
    }
  }

  @media (max-width: 800px) {
    padding: 0;
    align-items: center;
    text-align: center;

    h2 {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }
  }

  @media (max-width: 650px) {
    width: 90%;
  }
`;
