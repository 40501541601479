import styled from 'styled-components'


export const StyledContainer = styled.div`
background-color: white;
padding:180px 200px;


@media (max-width: 1200px) {
    padding: 150px 20px;
  }

  @media (max-width: 799px) and (min-width: 651px) {
    padding: 150px 20px;
  }


`

export const StyledTConten = styled.div`
color:#9599BB;
padding:10px;

`

export const StyledTitle = styled.h2`
font-size: 21px;
font-weight: 700;

@media (max-width: 850px) {
    font-size: 19px;
  }

  @media (max-width: 799px) and (min-width: 651px) {
    font-size: 19px;
  }
`
export const StyledSubTitle = styled.p`
font-size: 21px;

@media (max-width: 850px) {
    font-size: 19px;
  }

  @media (max-width: 799px) and (min-width: 651px) {
    font-size: 19px;
  }
`

export const Stylespadding = styled.br`
padding-button:1-px
`
