import styled from "styled-components";
import pix from "../../../images/pix.jpeg";
import mainImg from "../../../images/bg_overlay3.png";

export const Container = styled.div`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0 130px 0;
  position: relative;
`;
export const Opacity = styled.div`
  background-image: url(${mainImg});
  background-position: center left;
  background-size: cover;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Card = styled.div`
  background-image: url(${mainImg});
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  width: 1140px;
  height: 443px;
  background-color: #343f8a;
  border-radius: 10px;

  @media (max-width: 1050px) {
    flex-direction: column-reverse;
    width: 90vw;
    border-radius: 10px 0 10px 0;
  }

  @media (margin: 650px) {
    height: 478px;
  }

  @media (max-width: 800px) and (min-width: 651px) {
    height: 431px;
  }

  @media (max-width: 1050px) and (min-width: 801px) {
    height: 639px;
  }
`;

export const InfoText = styled.div`
  width: 570px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 30px;

  @media (max-width: 1050px) {
    width: 100%;
    margin: 0;
    text-align: center;
    align-items: center;
  }

  @media (max-width: 650px) {
    height: calc(478px / 2);
  }
  @media (max-width: 800px) and (min-width: 650px) {
    height: calc(431px / 2);
  }
  @media (max-width: 1050px) and (min-width: 801px) {
    height: 155px;
  }
`;

export const Image = styled.div`
  width: 570px;
  height: 443px;
  background-image: url(${pix});
  background-size: cover;
  background-position: center;
  border-radius: 10px 0 0 10px;

  @media (max-width: 650px) {
    height: calc(478px / 2);
  }

  @media (max-width: 1050px) {
    border-radius: 10px 0 0 0;
    width: 90vw;
  }

  @media (max-width: 800px) and (min-width: 650px) {
    height: calc(431px / 2);
  }

  @media (max-width: 1050px) and (min-width: 801px) {
    height: 484px;
  }
`;

export const Header = styled.h2`
  font-family: "Poppins", sans-serif;
  color: white;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.4em;
  box-sizing: border-box;
  padding-left: 20px;

  @media (max-width: 650px) {
    font-size: 28px;
    padding: 0;
  }
`;

export const TextArea = styled.ul`
  vertical-align: baseline;
  list-style-type: none;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding-left: 20px;
  background: transparent;
  margin-top: 20px;

  li {
    padding: calc(11px / 2);
  }

  @media (max-width: 650px) {
    padding-left: 0px;
  }
`;
