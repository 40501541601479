import styled from "styled-components";
import circle from "../../../images/circleComplete.png";

export const Container = styled.div`
  flex: 1 0 auto;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: ${(props) => props.marginBottom};
  height: 733px;

  position: relative;

  .purple {
    position: absolute;
    height: 50%;
    right: 0;
    width: 100vw;

    z-index: 1;

    @media (max-width: 800px) {
      height: 65%;
      top: 145px;
    }
    
		@media (max-width: 650px) {
      height: 65%;
      top: 235px;
    }

  }

  @media (max-width: 1024px) {
    margin-top: 50px;
  }
`;

export const BoxOpacity = styled.div`
  background-color: #f5f9ff;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const BoxInfo = styled.div`
  width: 570px;
  height: 533px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 70px;

  z-index: 2;

  h2 {
    color: #343f8a;
    font-size: 42px;
    font-weight: 600;
    line-height: 1.4em;
    letter-spacing: normal;
  }

  p {
    margin-top: 20px;
    font-size: 16px;
    color: #9599bb;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    background-color: #343f8a;
    color: #ffffff;
    padding: 20px 30px;
    border-radius: 10px;
    border: #343f8a solid 1px;
    margin-top: 40px;
    cursor: pointer;
    p {
      color: #ffffff;
      margin-top: 0;
      margin-right: 8px;
    }
    &:hover {
      background-color: #ffffff;
      color: #343f8a;
      border-color: #343f8a;
      p {
        color: #343f8a;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 50px;
    h2 {
      font-size: 33px;
    }
  }

  @media (max-width: 800px) {
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 20px 0;

    h2 {
      font-size: 28px;
    }
    p {
      font-size: 14px;
    }

    button {
      display: none;
    }
  }

  @media (max-width: 650px) {
    width: 90%;
  }
`;

export const BoxImg = styled.div`
  height: 533px;
  width: 570px;
  background-image: url(${circle});
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 464px;
    height: 514px;
  }
  button {
    font-size: 16px;
    font-weight: 400;
    background-color: #343f8a;
    color: #ffffff;
    padding: 20px 30px;
    border-radius: 10px;
    border: #343f8a solid 1px;
    margin-top: 40px;
    cursor: pointer;
    display: none;
    &:hover {
      background-color: #ffffff;
      color: #343f8a;
      border-color: #343f8a;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    height: 594px;
    margin-bottom: 30px;
    img {
      width: 424px;
      height: 474px;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        margin-right: 8px;
      }
    }

    @media (max-width: 650px) {
      width: 90%;
      img {
        width: 325px;
        height: 360px;
      }
    }
  }
`;
