import {
  LinkList,
  NavAnchor,
  StyleCloseButton,
  StyledSideModal,
  StyleImg,
  StyledModalBody
} from "./style";

import mainLogo from "../../images/sideModaLogo.png";
import { useEffect } from "react";

const SideModal = ({ setModalAnimation, modalAnimation }) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        modalAnimation ? setModalAnimation(0) : setModalAnimation(1);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  return (
    <>
      <StyledModalBody
        action={modalAnimation}
        onClick={() =>
          modalAnimation ? setModalAnimation(0) : setModalAnimation(1)
        }
      ></StyledModalBody>
      <StyledSideModal action={modalAnimation}>
        <div className="top-modal">
          <StyleImg src={mainLogo} alt="pixwave" width="130" />
          <StyleCloseButton
            onClick={() =>
              modalAnimation ? setModalAnimation(0) : setModalAnimation(1)
            }
          >
            X
          </StyleCloseButton>
        </div>

        <LinkList>
          <li>
            <NavAnchor href="/service">Para Você</NavAnchor>
          </li>

          <li>
            <NavAnchor href="/business">Para Empresas</NavAnchor>
          </li>

          <li>
            <NavAnchor href="/faq">Ajuda</NavAnchor>
          </li>
        </LinkList>
      </StyledSideModal>
    </>
  );
};

export default SideModal;
