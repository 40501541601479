import styled from "styled-components";

export const Container = styled.div`
  flex: 1 0 auto;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  background-color: #f5f9ff;
  position: relative;
  height: 626px;
  @media (max-width: 799px) {
    margin-top: 50px;
    height: 593px;
    flex-direction: column;
  }
  @media (max-width: 799px) {
    height: 509px;
  }
`;
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
`;

export const Card = styled.div`
  display: flex;
  padding: 76px;
  position: relative;
  bottom: 0;
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 10px;
    align-items: center;
  }
`;
export const Text = styled.p`
  width: 388px;
  text-align: ${(props) => props.align};
  color: #9599bb;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin-right: 80px;
  padding-left: ${(props) => props.paddingLeft};
  line-height: ${(props) => props.lineHeight};

  @media (max-width: 800px) {
    width: 100%;
    font-size: 14px;
  }
  @media (max-width: 650px) {
    text-align: center;
    margin: 0;
    width: 300px;
    margin-bottom: 30px;
    font-size: 14px;
  }

  @media (max-width: 799px) and (min-width: 651px) {
    text-align: center;
    margin: 0;
    margin-bottom: 30px;
  }
`;

export const BoxCount = styled.div`
  width: 500px;
  color: #9599bb;
  font-family: "Poppins", sans-serif;

  p + p {
    font-size: 150px;
    font-weight: 600;
    line-height: 1;
  }

  @media (max-width: 800px) and (min-width: 650px) {
    width: 100%;
    p + p {
      font-size: 130px;
      text-align: center;
    }
  }

  @media (max-width: 650px) {
    width: 300px;
    p + p {
      font-size: 100px;
    }
  }

  @media (max-width: 1220px) and (min-width: 801px) {
    width: 400px;

    p + p {
      font-size: 130px;
    }
  }
`;

export const TextCount = styled.p`
  padding-left: 80px;
  font-size: 14px;

  @media (max-width: 800px) and (min-width: 650px) {
    width: 100%;
    text-align: left;
    padding-left: 30px;
  }

  @media (max-width: 650px) {
    padding-left: 0;
  }
`;
