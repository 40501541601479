import styled from "styled-components";
import FaqImg from "../../../images/faqImg.png";
import faqmob from "../../../images/mobileFaq.png";

export const ContainerMain = styled.div`
  flex: 1 0 auto;
  display: block;
`;

export const BoxSection = styled.section`
  background-image: url(${FaqImg});

  background-color: white;
  background-size: cover;
  background-position: center center;
  height: 385px;
  text-align: center;
  position: relative;
  display: block;

  @media (max-width: 650px) {
    background-image: cover;
    background-color: #10153d;
    height: 320px;
  }
`;

export const BoxOpacity = styled.div`
  background-color: #10153d;
  background-size: cover;
  background-position: right 20%;
  height: 100%;
  text-align: center;
  position: relative;
  display: block;
  opacity: 0.8;

  @media (max-width: 650px) {
    background-image: cover;
    background-color: #10153d;
    opacity: 0.8;
  }
`;

export const ContainerHeader = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  color: white;
`;

export const Header = styled.h1`
  line-height: 1.4em;
  font-size: 50px;
  font-family: "Poppins";
  font-weight: 600;

  margin-top: 70px;

  @media (max-width: 650px) {
    font-size: 39px;
  }
`;

export const Menu = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
`;

export const StyledSection = styled.section`
  background-image: url(${FaqImg});
  background-color: #27348a;
  background-size: cover;
  height: 509px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;

  @media (max-width: 1000px) {
    background-image: url(${faqmob});
    background-position: bottom;
    height: 450px;
  }
`;

export const StyledBackgorund = styled.div`
  background-color: rgba(39, 52, 138, 0.7);
  width: 100%;

  display: flex;
  justify-content: center;
`;

export const StyledDiv = styled.div`
  text-align: center;
  display: block;
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const StyledImg = styled.img`
  width: 447px;
  height: 480px;
  border-radius: 4px;
  position: absolute;
  bottom: 0px;
`;

export const StyledDiv3 = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 329px;
  height: 120px;
  background-size: cover;
`;

export const StyledH1 = styled.h1`
  width: 329px;
  height: 120px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 59px;
  /* or 140% */

  text-align: center;

  color: #ffffff;
`;


export const StyledButton = styled.button`
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 14px;

  box-sizing: border-box;

  width: 157px;
  height: 46px;

  background: #009ee2;
  border: 2px solid #009ee2;
  border-radius: 4px;
`;

export const StyledP = styled.p`
  margin-top: 5px;
  text-align: center;
  justify-content: center;

  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  hyphens: manual;
  line-height: 25.2px;
  color: white;

  display: flex;
  align-items: center;

  svg {
    color: #9082FF;
    margin-left: 5px;
    margin-right: 5px;
    width: 31.5px;
    height: 21px;
}

`;