import styled from 'styled-components'

export const StyledSection = styled.section`
height: 1530px;
text-align: center;
position: relative;
display: flex;
justify-content: center;
background-color: #27348a;
flex-direction: column;
align-items: center;

@media (max-width: 800px) {
    align-items: center;
    justify-content: center;
    height: 2300px;
    flex-direction: column;
    margin-top: 0;

}

`

export const StyledSection2 = styled.section`
background-color: white;
background-size: cover;
height: 540px;
text-align: center;
position: relative;
display: flex;
justify-content: center;
margin-bottom: 135px;

@media (max-width: 800px) {
    height: 810;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

`

export const StyledDiv = styled.div`
text-align: center;
display: block;

width: 75%;
height: 900px;
display: flex;
justify-content: center;
align-items: center;


@media (max-width: 800px) {
    height: 1680px;
}

@media (max-width: 650px) {
    width: 95%;

}

`

export const StyledItens = styled.div`
text-align: center;
display: block;

width: 70%;
height: 360px;
display: flex;
justify-content: center;
align-items: center;

@media (max-width: 800px) {
height: 720;
}
`

export const StyledDiv2 = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
width: 100%;

`

export const StyledDiv3 = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
width: 100%;
height: 40%;
max-width: 1150px;

`

export const StyledH1 = styled.h1`
width: 220px;
height: 70px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 1.5em;
/* or 294% */

text-align: center;
`

export const StyledP = styled.p`
color: rgb(255, 255, 255);

font-family: 'Poppins';
font-style: normal;
font-size: 14px;
font-weight: 400;
hyphens: manual;
line-height: 1.8em;
margin-bottom: 2px;
text-align: center;
margin-top: 50px;

@media (max-width: 800px) {
    width: 300px;
    height: 100px;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 30px;
}

@media (max-width: 450px) {
    height: 100px;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 30px;
}
`

export const StyledP3 = styled.p`
height: 26px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 25px;
text-align: center;
color: #9599BB;


`

export const StyledDiv4 = styled.div`
box-sizing: border-box;
width: 360px;
height: 305px;
color: #27348a !important;
background: white;
border: 1px solid #009EE2;
border-radius: 8px;

display: flex;
flex-direction: column;
align-items: center;

p {
   color: rgb(149, 153, 187);
}

svg{
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #343F8A;
    font-weight: 900;
    font-size: 20px;
  }


@media (max-width: 800px) {
    height: 260px;
    margin-bottom: 20px;
    width: 100%;
}

@media (max-width: 800px) {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
`

export const StyledDiv4blue = styled.div`

box-sizing: border-box;
width: 360px;
height: 305px;

background: #27348a;
border: 2px solid white;
border-radius: 8px;
color: white !important;
display: flex;
flex-direction: column;
align-items: center;

svg{
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    font-weight: 900;
    font-size: 20px;
  }

@media (max-width: 800px) {
    height: 260px;
    margin-bottom: 20px;
    width: 100%;
}

@media (max-width: 800px) {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
`

export const StyledDiv7 = styled.div`
width: 300px;
height: 118px;
margin-top: 10px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 59px;
/* or 294% */

text-align: center;

`

export const StyledDiv6 = styled.div`
width: 124px;
height: 163px;

`

export const StyledDiv5 = styled.div`
gap:10px;
margin: 30px 0;
display: flex;
justify-content: space-around;

@media (max-width: 800px) {
    flex-direction: column;
    margin: 0;
    align-items: center;
}
`

export const Styledh2 = styled.p`
flex-direction: column;
font-size: 32px;
line-height: 45px;
font-weight: 400;
`

export const StyledP2 = styled.p`

font-family: 'Poppins';
font-style: normal;
font-size: 14px;
font-weight: 400;
hyphens: manual;
line-height: 1.8em;
text-align: center;

`

export const StyledP4 = styled.p`
height: 60px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 42px;
line-height: 55px;
text-align: center;
color: #051C1D;
`

export const Styledlogo = styled.img`
box-sizing: border-box;
width: 50x;
height: 50px;

background: url(8-03);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
border-radius: 4px;
margin: 25px 0 11px;
`

export const StyledButton = styled.button`
width: 167px;
height: 37px;
background: #009EE2;
border: 2px solid #009EE2;
border-radius: 2px;
color: white;
font-family: 'Poppins';
font-style: normal;
font-weight: 800;
font-size: 13px;
line-height: 14px;
margin-top: 10px;
`


export const StyledDivTitle = styled.div`
width: 1319px;
height: 100px;


text-align: center;

color: rgb(255, 255, 255);
font-family: "Poppins", sans-serif;
font-size: 42px;
font-weight: 600;
hyphens: manual;
line-height: 1.4em;
margin-top: 50px;

@media (max-width: 800px) {
    width:420px;
    height: 180px;
    font-size: 35px;
    margin-top: 0px;
}

@media (max-width:450px) {
    width:350px;
    height: 120px;
    font-size: 25px;
}

`