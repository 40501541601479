import styled from "styled-components";
import aboutus from "../../../images/aboutusBackground.jpg";

export const ContainerMain = styled.div`
  flex: 1 0 auto;
  display: block;
`;

export const BoxSection = styled.section`
  background-image: url(${aboutus});

  background-color: white;
  background-size: cover;
  background-position: center center;
  height: 385px;
  text-align: center;
  position: relative;
  display: block;

  @media (max-width: 650px) {
    background-image: cover;
    background-color: #10153d;
    height: 320px;
  }
`;

export const BoxOpacity = styled.div`
  background-color: #10153d;
  background-size: cover;
  background-position: right 20%;
  height: 100%;
  text-align: center;
  position: relative;
  display: block;
  opacity: 0.8;

  @media (max-width: 650px) {
    background-image: cover;
    background-color: #10153d;
    opacity: 0.8;
  }
`;

export const ContainerHeader = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  color: white;
`;

export const Header = styled.h1`
  line-height: 1.4em;
  font-size: 50px;
  font-family: "Poppins";
  font-weight: 600;

  margin-top: 70px;

  @media (max-width: 650px) {
    font-size: 39px;
  }
`;

export const Menu = styled.ul`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;

  svg {
    color: #9082FF;
    margin-left: 5px;
    margin-right: 5px;
    width: 31.5px;
    height: 21px;
}

  li a {
    text-decoration: none;
    color: white;
  }
`;
