import styled from "styled-components";

export const Container = styled.div`
  flex: 1 0 auto;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 200px;
  padding: 100px 0;
  @media (max-width: 799px) {
    flex-direction: column-reverse;
    margin-top: 100px;
    padding: 0;
  }
`;

export const InfoText = styled.div`
  margin-right: 50px;
  width: 410px;

  @media (max-width: 650px) {
    margin-top: 20px;
    margin-right: 0;
    width: 280px;
    text-align: center;
  }

  @media (max-width: 799px) and (min-width: 651px) {
    width: 400px;
    text-align: center;
    margin-right: 0;
    margin-top: 20px;
  }
`;

export const Image = styled.img`
  border-radius: 30px;
  border: solid none;
  box-sizing: border-box;
  width: 760px;

  @media (max-width: 650px) {
    border-radius: 25px;
    width: 250px;
  }

  @media (max-width: 1220px) and (min-width: 650px) {
    border-radius: 25px;
    width: 450px;
  }
`;

export const Header = styled.h2`
  font-family: "Poppins", sans-serif;
  color: #343f8a;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.4em;
  box-sizing: border-box;

  @media (max-width: 650px) {
    font-size: 28px;
  }
`;

export const TextArea = styled.ul`
  vertical-align: baseline;
  list-style-type: none;
  color: #9599bb;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  padding-left: 20px;
  background: transparent;
  margin-top: 20px;

  li {
    padding: calc(11px / 2);
  }

  @media (max-width: 650px) {
    padding-left: 0px;
  }
`;

export const Styleddiv = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 14px;
    width: 17.5px;
    margin-right: 5px;
  }
`;
