import styled from "styled-components";
import circle from "../../../images/circleComplete.png";
import structure from "../../../images/structure.png";

export const Container = styled.div`
  flex: 1 0 auto;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  margin-bottom: ${(props) => props.marginBottom};

  @media (max-width: 650px) {
    margin-top: 30px;
  }
`;

export const Card = styled.div`
  width: 1140px;
  height: 533px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background-color: #606581;
  border-radius: 20px;

  div + div {
    width: 50%;
    background-image: url(${structure});
    background-position: -240px;
    border-radius: 0 20px 20px 0;
    border: solid #9599bb 2px;
  }

  @media (max-width: 650px) {
    width: 320px;
    height: 505px;
    flex-direction: column;

    div + div {
      height: 292px;
      width: 100%;
      border: none;
      border-radius: 0 0 20px 20px;
      background-size: cover;
      background-position: center;
    }
  }

  @media (max-width: 799px) and (min-width: 651px) {
    flex-direction: column;
    width: 550px;
    height: 650px;

    div + div {
      height: 330px;
      width: 100%;
      border: none;
      border-radius: 0 0 20px 20px;
      background-size: cover;
      background-position: center;
    }
  }

  @media (max-width: 1220px) and (min-width: 800px) {
    width: 900px;
  }
`;

export const BoxInfo = styled.div`
  width: calc(1140px / 2);
  height: 100%;
  background-image: url(${circle});
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
  color: white;

  @media (max-width: 650px) {
    height: 213px;
    width: 100%;
    text-align: center;
    padding: 20px;
  }

  @media (max-width: 799px) and (min-width: 651px) {
    height: 320px;
    width: 100%;
    margin: auto;
    align-items: center;
    text-align: center;
    padding: 0;
  }

  @media (max-width: 1220px) and (min-width: 650px) {
    width: calc(900px / 2);
  }
`;

export const Text = styled.p`
  line-height: 1.8em;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  width: 445px;

  @media (max-width: 1220px) {
    font-size: 13px;
    width: 320px;
  }
  @media (max-width: 800px) and (min-width: 650px) {
  margin: auto;
  }

  @media (max-width: 650px) {
    font-size: 14px;
    width: 280px;
  }
`;

export const Heading = styled.h2`
  line-height: 1.8em;
  font-family: "Poppins", sans-serif;
  font-size: 42px;
  font-weight: 600;

  @media (max-width: 1220px) {
    font-size: 38px;
  }
  @media (max-width: 650px) {
    font-size: 28px;
  }
`;
