import { Container, Header, Image, InfoText, TextArea, Styleddiv } from "./style";
import peopleDiversity from "../../../images/peopleDiversity.png";
import { FadeIn, FadeInUp } from "../../../Styles";
import { useSelector } from "react-redux";
import {
  FaCheck
} from "react-icons/fa";

const IntoAboutUs = () => {
  let token = useSelector((state) => state).language.Language;

  return (
    <Container>
      <InfoText>
        <FadeInUp duration="1.5s" delay="1s">
            <Header>Somos Referência!</Header>
        </FadeInUp>
          <TextArea>
            <Styleddiv>
              <FaCheck />
            <li>
              Em acesso ao sistema financeiro internacional para brasileiros.
            </li>
            </Styleddiv>
            <Styleddiv>
              <FaCheck />
            <li>Em abertura de contas para brasileiros no exterior.</li>
            </Styleddiv>
            <Styleddiv>
              <FaCheck />
            <li>Em acreditar no potencial da diversificação no exterior.</li>
            </Styleddiv>
            <Styleddiv>
              <FaCheck />
            <li>Em proporcionar liberdade aos nossos clientes</li>
            </Styleddiv>
          </TextArea>
      </InfoText>

      <FadeIn duration="1.5s" delay="1s">
        <Image
          src={peopleDiversity}
          alt={
            token === "Portugues"
              ? "Diversidade de pessoas"
              : "Diversity of people"
          }
        />
      </FadeIn>
    </Container>
  );
};

export default IntoAboutUs;
