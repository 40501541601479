import cardImage from "../../../images/Card.png";
import { BoxImg, BoxInfo, Card, Container } from "./style";
import { FadeIn, FadeInUp } from "../../../Styles";
import { useSelector } from "react-redux";
import purple from "../../../images/rightPurple.png";

const Suport = () => {
  let token = useSelector((state) => state).language.Language;

  return (
    <Container>
      <img className="purple" src={purple} alt="" />
      <Card>
        <FadeInUp duration="1.5s" delay="1s">
            <BoxInfo>
              <h2>Suporte financeiro de credibilidade global</h2>
              <p>
                Dentre os investidores que nos suportam financeiramente estão
                alguns dos nomes mais importantes da indústria financeira do
                Brasil e do mundo.
              </p>
            </BoxInfo>
        </FadeInUp>
        <BoxImg>
          <FadeIn duration="1.5s" delay="1s">
            <img
              src={cardImage}
              alt={
                token === "Portugues" ? "Cartões Pix Wave" : "Pix Wave Cards"
              }
            />
          </FadeIn>
        </BoxImg>
      </Card>
    </Container>
  );
};

export default Suport;
