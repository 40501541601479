import { Container, Header, Image, InfoText, Opacity, TextArea } from "./style";
import peopleDiversity from "../../../images/partner.jpg";
import { FadeIn, FadeInUp } from "../../../Styles";
import { useSelector } from "react-redux";

const PartnerComponent = () => {
  let token = useSelector((state) => state).language.Language;

  return (
    <Container>
      <Opacity></Opacity>
      <InfoText>
        <FadeInUp duration="1.5s" delay="1s">
          <Header>PW Partner</Header>
        </FadeInUp>
        <TextArea>
          Tenha acesso ao novo, faça parte e tenha acesso a produtos que te
          oferecem serviços, ofertas e benefícios exclusivos.
        </TextArea>
      </InfoText>

      <FadeIn duration="1.5s" delay="1s">
        <Image>
          <img
            src={peopleDiversity}
            alt={
              token === "Portugues"
                ? "Diversidade de pessoas"
                : "Diversity of people"
            }
          />
        </Image>
      </FadeIn>
    </Container>
  );
};

export default PartnerComponent;
