import { useSelector } from "react-redux";
import readyImage from "../../../images/phones.png";
import { FadeIn, FadeInUp } from "../../../Styles";
import {
  StyledButton,
  StyledDiv,
  StyledDiv2,
  StyledDiv3,
  StyledH1,
  StyledImg,
  StyledP,
  StyledSection,
  StyledDiv6
} from "./style";

function ReadyComponent() {
  let token = useSelector((state) => state).language.Language;
  return (
    <StyledSection>
      <StyledDiv>
        <FadeIn duration="1.5s" delay="1s">
          <StyledDiv2>
            <StyledImg src={readyImage} />
          </StyledDiv2>
        </FadeIn>
        <StyledDiv6>
          <FadeInUp duration="1.5s" delay="1s">
            <StyledDiv3>
              {token === "Portugues" ? (
                <StyledH1>Pronto para começar?</StyledH1>
              ) : (
                <StyledH1>Ready to start?</StyledH1>
              )}
              {token === "Portugues" ? (
                <StyledP>
                  Está esperando o quê? Descubra o melhor que a PixWave tem a oferecer para você.
                </StyledP>
              ) : (
                <StyledP>
                  What are you waiting for? Discover the best that PixWave has to
                  offer you
                </StyledP>
              )}
              {token === "Portugues" ? (
                <StyledButton><a href="https://bank.pixwave.com.br/type-create-account" >Abra sua conta</a></StyledButton>) : (
                <StyledButton>Open account</StyledButton>
              )}
            </StyledDiv3>
          </FadeInUp>
        </StyledDiv6>
      </StyledDiv>
    </StyledSection>
  );
}

export default ReadyComponent;
