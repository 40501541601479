import { BoxVideo } from "./style";

const Video = () => {
  return (
    // <Container>
    <BoxVideo
      frameBorder="0"
      allowFullScreen="1"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      src="https://www.youtube.com/embed/XHOmBV4js_E?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fpixwave.com.br&widgetid=1"
    ></BoxVideo>
    // {/* </Container> */}
  );
};

export default Video;
