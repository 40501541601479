import styled from "styled-components";
import mainImg from "../../../images/mainBackground.jpg";

export const StyledMain = styled.main`
  flex: 1 0 auto;
  display: block;
`;
export const StyledSection = styled.section`
  background-image: url(${mainImg});
  background-color: white;
  background-size: cover;
  background-position: right 20%;
  height: 900px;
  text-align: center;
  position: relative;
  display: block;
  background-position: 0px 84px;

  @media (max-width: 800px) {
    background-image: url(${mainImg});
    background-position: 68%;
    background-size: cover;
  }
  @media (max-width: 1220px) and (min-width: 81px) {
    background-position: 70%;
  }
`;

export const StyledDiv1 = styled.div`
  height: 100%;
  display: flex;
  max-width: 1200px;
  padding-left: 15%;
  padding-right: 15%;
  width: 100%;
  @media (max-width: 800px) {
    padding-left: 0%;
    padding-right: 0%;
    justify-content: center;
  }
`;

export const StyledDiv2 = styled.div`
  display: flex;
  -moz-box-align: center;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin: 115px;
  @media (max-width: 800px) {
    margin: 0;
    width: 100%;
    align-items: flex-end;
    align-items: center;
  }
  @media (max-width: 1220px) and (min-width: 81px) {
    margin: 0;
  }
`;

export const Styledh2 = styled.p`
  flex-direction: column;
  font-size: 32px;
  line-height: 45px;
  font-weight: 400;
`;

export const StyledDiv3 = styled.div`
  max-width: 600px;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

export const StyledDiv5 = styled.div`
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  @media (max-width: 800px) {
    margin: 20px 0;
    width: 90%;
  }
`;

export const CardButton = styled.div`
  width: 90%;
  display: flex;
  margin-top: 30px;
  @media (max-width: 650px) {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
  @media (max-width: 800px) and (min-width: 651px) {
    button {
      width: 50%;
    }
  }
`;

export const StyledH1 = styled.h1`
  width: 530px;
  height: 115px;
  color: #ffffff;
  font-family: "Montserrat",Sans-serif;
  font-size: 50px;
  font-weight: 500;
  line-height: 0.95em;
  margin-bottom: 0px;

  @media (max-width: 800px) {
    text-align: center;
    font-size: 39px;
    margin: 18px;
    height: auto;
  }

  @media (max-width: 460px) {
    text-align: center;
    font-size: 39px;
    margin: 18px;
    height: auto;
    width: 330px;
  }
`;

export const StyledP = styled.p`
  width: 477px;
  height: 60px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: white;

  @media (max-width: 800px) {
    text-align: center;
    width: 90%;
    font-size: 14px;
    height: auto;
  }
`;

export const StyledButton = styled.button`
  width: 210px;
  height: 65px;
  background: #009ee2;
  border: 2px solid #009ee2;
  border-radius: 5px;
  cursor: pointer;
  color: white;

  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #343f8a;
  border-radius: 10px 10px 10px 10px;
  background-color: #343f8a;
  padding: 20px 40px 20px 40px;

  a {
    color: white;
    text-decoration: none; 
  }

  @media (max-width: 650px) {
    margin: 0;
  }
  @media (max-width: 800px) {
    margin: 0;
  }
`;

export const StyledButton2 = styled.button`
  width: 210px;
  height: 65px;

  cursor: pointer;
  color: white;
  background: unset;
  border: 0;

  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  font-weight: 500;
`;

export const StyledDiv4 = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  border: 2px solid #ffffff;
  border-radius: 10px;
  width: 180px;
  height: 162px;
  color: white;
  flex-direction: column;
  margin-left: ${(props) => props.marginLeft};

  &:hover {
    border: 2px solid rgba(0, 158, 226, 0.6);
  }
  @media (max-width: 800px) {
    margin: 0;
    width: 50%;
  }
`;
