import {
  Header,
  StyledBackgorund,
  StyledDiv,
  StyledP,
  StyledSection,
} from "./style";
import {
  BiRightArrowAlt
} from "react-icons/bi";

function MainComponent() {
  return (
    <StyledSection>
      <StyledBackgorund>
        <StyledDiv>
          <Header>Serviços</Header>

          <StyledP>
            Home <BiRightArrowAlt />
            Serviços
          </StyledP>
        </StyledDiv>
      </StyledBackgorund>
    </StyledSection>
  );
}

export default MainComponent;
