import { StyledBackgorund, StyledDiv, StyledDiv3, StyledH1, StyledP, StyledSection } from "./style";
import {
  BiRightArrowAlt
} from "react-icons/bi";

function MainComponent() {
    return (
      <StyledSection>
        <StyledBackgorund>
        <StyledDiv>
          <StyledDiv3>
            <StyledH1>Para Empresas</StyledH1>
          <StyledP>Home <BiRightArrowAlt />Para Empresas</StyledP>
          </StyledDiv3>
        </StyledDiv>
        </StyledBackgorund>
      </StyledSection>
    );
  }
  
  export default MainComponent;