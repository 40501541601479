/* eslint-disable react-hooks/exhaustive-deps */
import FooterComponent from "./Footer";
import HeaderComponent from "./Header";
import MainComponent from "./Main";
import IntroComponent from "./Intro";
import CardComponent from "./card";
import BankComponent from "./bank";
import PaymentComponent from "./Payment";
import ReadyComponent from "./Ready";
import { StyledApp } from "./style";
import { useEffect, useState } from "react";
import SideModal from "../SideModal";

const HomeComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalAnimation, setModalAnimation] = useState(0);

  useEffect(() => {
    showModal === true
      ? setTimeout(() => {
          modalAnimation ? setShowModal(true) : setShowModal(false);
        }, 490)
      : modalAnimation
      ? setShowModal(true)
      : setShowModal(false);
  }, [modalAnimation]);

  return (
    <StyledApp>
      {showModal && (
        <SideModal
          setModalAnimation={setModalAnimation}
          modalAnimation={modalAnimation}
        />
      )}
      <HeaderComponent
        setModalAnimation={setModalAnimation}
        modalAnimation={modalAnimation}
      />
      <MainComponent />
      <IntroComponent />
      <CardComponent />
      <BankComponent />
      <PaymentComponent />
      <ReadyComponent />
      <FooterComponent />
    </StyledApp>
  );
};

export default HomeComponent;
