import { Container, Box, Header, Text, Button } from "./style";
import QuestComponent from "../Quest";
import { FadeInUp } from "../../../Styles";

const FrequentComponent = () => {
  const quests = [
    {
      id: 1,
      quest: "Posso fazer transferências instantâneas com minha conta PixWave?",
      response:
        "Sim! Com a sua conta PixWave possível enviar e receber seu dinheiro na hora, além de fazer a conversão e acompanhar suas movimentações de forma fácil e eficiente.",
    },
    {
      id: 2,
      quest: "Como abrir uma conta na PixWave?",
      response:
        "A solicitação de abertura de conta é feita de forma 100% online e gratuita, oferecendo maior praticidade e agilidade para que você viva uma vida financeira global sem burocracias com a gente.",
    },
    {
      id: 3,

      quest: "O que são ativos digitais?",
      response:
        "São ativos virtuais, protegidos por criptografia, o que torna quase impossível sua falsificação. Estão presentes exclusivamente em registros digitais, cujas operações são executadas e armazenadas em uma rede de computadores. ",
    },
    {
      id: 4,

      quest: "O que é PW Partner?",
      response:
        "É um produto exclusivo que te oferece serviços, ofertas e benefícios exclusivos por fazer parte.",
    },
  ];

  return (
    <Container>
      <FadeInUp break={true} duration="1.5s" delay="1s">
        <Box justify="center" alignItems="start" width="570px">
          <Header>
            Perguntas <br /> Frequentes
          </Header>
          <Text>
            <span>
              Possui dúvidas? Veja as respostas para nossas perguntas mais
              frequentes ou:
            </span>
          </Text>
          <Button>
            Entre em contato <span>→</span>{" "}
          </Button>
        </Box>
      </FadeInUp>

      <FadeInUp break={true} duration="1.5s" delay="1s">
        <Box
          size={true}
          justify="space-around"
          alignItems="center"
          width="570px"
        >
          {quests.map((item, index) => (
            <QuestComponent key={item.id} newId={index} quests={item} />
          ))}
        </Box>
      </FadeInUp>
    </Container>
  );
};

export default FrequentComponent;
