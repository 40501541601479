import styled from "styled-components";
import mainImg from "../../../images/bg_overlay3.png";

export const Container = styled.div`
  position: relative;
  flex: 1 0 auto;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  height: 658px;
  background-color: #f5f9ff;
  @media (max-width: 650px) {
    flex-direction: column-reverse;

    height: 559px;
  }

  @media (max-width: 800px) and (min-width: 651px) {
    flex-direction: column-reverse;
    height: 732px;
  }

  @media (max-width: 1024px) and (min-width: 801px) {
    flex-direction: column-reverse;
    padding-top: 40px;
    height: 808px;
  }
`;
export const Opacity = styled.div`
  background-image: url(${mainImg});
  background-position: center left;
  background-size: cover;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const InfoText = styled.div`
  margin-right: 50px;
  width: 410px;
  padding-left: 40px;
  @media (max-width: 650px) {
    margin-top: 20px;
    margin-right: 0;
    width: 280px;
    text-align: center;
    padding: 0;
  }

  @media (max-width: 1050px) and (min-width: 651px) {
    width: 90%;
    text-align: center;
    margin-right: 0;
    margin-top: 20px;
    padding: 0;
  }
`;

export const Image = styled.img`
  border: solid none;
  box-sizing: border-box;
  width: 793px;
  height: 529px;

  @media (max-width: 650px) {
    width: 250px;
    height: 213px;
  }

  @media (max-width: 800px) and (min-width: 650px) {
    width: 633px;
    height: 422px;
  }

  @media (max-width: 1024px) and (min-width: 801px) {
    width: 813px;
    height: 542px;
  }
`;

export const Header = styled.h2`
  font-family: "Poppins", sans-serif;
  color: #343f8a;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.4em;
  box-sizing: border-box;

  @media (max-width: 650px) {
    font-size: 28px;
  }

  @media (max-width: 1050px) and (min-width: 801px) {
    font-size: 33px;
  }
`;

export const TextArea = styled.p`
  vertical-align: baseline;
  color: #9599bb;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  background: transparent;
  margin-top: 20px;

  @media (max-width: 650px) {
    padding-left: 0px;
  }
`;
