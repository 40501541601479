import { useSelector } from "react-redux";
import { FadeInUp } from "../../../Styles";
import exchange from "../../../images/exchange.svg";
import { BoxImg, BoxInfo, BoxOpacity, Container } from "./style";
import { FaArrowRight } from "react-icons/fa";
import purple from "../../../images/leftPurple.png";

const Exchange = () => {
  let token = useSelector((state) => state).language.Language;

  return (
    <FadeInUp duration="1.5s" delay="1s">
      <Container>
        <BoxOpacity>
          <img className="purple" src={purple} alt="" />
          <BoxInfo>
            <h2>Câmbio Instantâneo e Integrado</h2>
            <p>
              A PixWave criou uma plataforma 100% digital onde você tem todo
              suporte para crescer em qualquer cenário.
            </p>
            <button>
              <a href="https://bank.pixwave.com.br/type-create-accountS">Abra sua conta</a>
              <FaArrowRight />
            </button>
          </BoxInfo>
          <BoxImg>
            <img
              src={exchange}
              alt={token === "Portugues" ? "Câmbio" : "Exchange"}
            />
            <button>
              <a href="https://bank.pixwave.com.br/type-create-account" >Abra sua conta</a>
              <FaArrowRight />
            </button>
          </BoxImg>
        </BoxOpacity>
      </Container>
    </FadeInUp>
  );
};

export default Exchange;
