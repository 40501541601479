import wallet from "../../../images/walletDigital.jpg";
import { FadeInUp } from "../../../Styles";
import { BoxImg, BoxText, Container } from "./style";

const WalletDigital = () => {
  return (
    <Container>
      <BoxImg src={wallet} alt="Pagamento por aprocximação" />
      <BoxText>
        <div>
          <FadeInUp duration="1.5s" delay="1s">
            <h2>Carteira Digital</h2>
            <p>
              Faça pagamentos por aproximação ou utilize os cartões PixWave nas
              principais carteiras digitais para simplificar seu dia a dia.
            </p>
          </FadeInUp>
        </div>
      </BoxText>
    </Container>
  );
};

export default WalletDigital;
