import styled from 'styled-components'
import bankmob from '../../../images/bank-mobile.png'
import introImg from '../../../images/intro.jpg'

export const StyledSection = styled.section`
background-image: url(${introImg});
background-color: #27348A;
background-size: cover;
height: 409px;
text-align: center;
position: relative;
display: flex;
justify-content: center;
background-position: center;

@media (max-width: 1000px) {
    background-image: url(${bankmob});
    background-position: bottom;
    height: 450px;
}

`

export const StyledBackgorund = styled.div`
background-color: rgb(16, 21, 61);
opacity: 0.8;
width: 100%;

display: flex;
justify-content: center;

`

export const StyledDiv = styled.div`
text-align: center;
display: block;
width: 70%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;

`

export const StyledDiv2 = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
width: 100%;

`

export const StyledImg = styled.img`
width: 447px;
height: 480px;
border-radius: 4px;
position: absolute;
bottom: 0px;

`

export const StyledDiv3 = styled.div`
text-align: center;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
justify-content: center;
width: 329px;
height: 100px;
background-size: cover;

`

export const StyledH1 = styled.h1`
width: 329px;
height: 120px;

font-family: "Poppins", sans-serif;
font-size: 42px;
font-weight: 600;
hyphens: manual;
line-height: 58.8px

text-align: center;

color: #FFFFFF;

`

export const StyledP = styled.p`

margin-top: 5px;
text-align: center;


font-family: "Poppins", sans-serif;
font-size: 16px;
font-weight: 400;
hyphens: manual;
line-height: 25.2px;
color: white;

display: flex;
align-items: center;

svg {
    color: #9082FF;
    margin-left: 5px;
    margin-right: 5px;
    width: 31.5px;
    height: 21px;
}


`

export const StyledSeta = styled.img`
width: 21px;
height: 17px;

`
