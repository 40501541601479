import styled, { keyframes } from "styled-components";

const openColor = keyframes`
	0% {
			background-color: #35418a;
			color: #ffffff;
		}
		
	100% {
		background-color: #ffffff;
		color: #35418a;
	}
`;

const closeColor = keyframes`
	0% {
		background-color: #ffffff;
		color: #35418a;
	}
	
	100% {
		background-color: #35418a;
		color: #ffffff;
	}
`;

const openHeight = (prop) => keyframes`
	from{
		height: 0;
		padding: 0;
		color: #ffffff;
	}
	to{
		height: ${prop.size}px;
		color: #9599bb;
	}
`;

const closeHeight = (prop) => keyframes`
	from{
		height: ${prop.size}px;
		color: #9599bb;
	}
	to{
		height: 0;
		padding: 0;
		color: #ffffff;
	}
`;

export const Button = styled.button`
  box-sizing: border-box;

  width: 100%;
  height: auto;
  margin: 6px 0;

  position: relative;

  border-radius: 10px;
  border: 2px solid #5846e026;
  cursor: pointer;
  padding: 15px 50px 15px 20px;

  animation: ${(props) => (props.action ? closeColor : openColor)} 0.5s;

  background-color: ${(props) => (props.action ? "#35418a" : "#ffffff")};
  color: ${(props) => (props.action ? "#ffffff" : "#35418a")};

  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  text-align: start;

  @media (max-width: 800px) {
    font-size: 14px;
    width: 250px;
  }

  @media (max-width: 1024px) {
    width: ${(props) => (props.widthSize ? "100%" : "95%")};
  }
`;

export const Response = styled.p`
  width: 100%;
  height: ${(props) => props.size}px;

  background: transparent;
  padding: 20px 50px 20px 40px;

  animation: ${(props) =>
      props.action ? openHeight(props.size) : closeHeight(props.size)}
    1s;

  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  color: #9599bb;
  text-align: start;
`;

export const StyledBotton = styled.img`
  margin-left: 10px;
  width: 14px;
  height: 8px;
  @media (max-width: 800px) {
    margin: 0 10px 0 5px;
  }
`;

export const ArrowSpan = styled.span`
  position: absolute;
  right: 5%;
  top: 36%;
`;

export const GetHeight = styled.div`
  width: 100%;

	position: absolute;

	left: -1000%;

  padding: 20px 50px 20px 40px;
	

  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  color: transparent;
  text-align: start;

  #getHeight {
    background: red;
  }
`;