import deposit from "../../../images/deposit.jpg";
import { BoxInfo, Card, Container } from "./style";
import { FadeInUp } from "../../../Styles";

const Deposit = () => {

  return (
        <FadeInUp duration="1.5s" delay="1s">
    <Container>
      <Card>
        <img
          src={deposit}
          alt="Mulher guardando cartão"
        />
          <BoxInfo>
              <h2>Fazer um Depósito</h2>
              <p>
                Uma transferência via TED ou PIX é o que você precisa para
                enviar recursos para sua conta PixWave, sempre de forma segura e
                instantânea.
              </p>
          </BoxInfo>
      </Card>
    </Container>
            </FadeInUp>
  );
};
export default Deposit;
