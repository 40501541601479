import logo2 from "../../../images/blueArrow.png";
import logo1 from "../../../images/lock.png";
import logo5 from "../../../images/Pix.png";
import {
  StyledButton,
  StyledDiv,
  StyledWallet,
  StyledDiv2,
  StyledDiv3,
  StyledDiv4,
  StyledDiv5,
  StyledH1,
  StyledP,
  StyledP2,
  StyledSection,
  Styledlogo,
} from "./style";
import { FadeInUp } from "../../../Styles";
import { FaArrowRight } from "react-icons/fa";

function BankComponent() {
  return (
    <FadeInUp duration="1.5s" delay="1s">
      <StyledSection>
        <StyledDiv>
          <StyledDiv2>
            <StyledH1>Um banco que realmente fala com você</StyledH1>

            <StyledP>
              A PixWave é um banco digital que torna a sua vida financeira cada
              vez mais segura, simples e eficaz. Chegamos para revolucionar o
              mercado financeiro.
            </StyledP>
            <StyledButton>
              <a href="https://bank.pixwave.com.br/type-create-account">Abrir minha conta</a>
              <FaArrowRight />
            </StyledButton>
          </StyledDiv2>
          <StyledDiv3>
            <StyledDiv5>
              <StyledDiv4>
                <Styledlogo src={logo1} />
                <StyledP2>Transferências</StyledP2>
              </StyledDiv4>
              <StyledDiv4>
                <Styledlogo src={logo2} />
                <StyledP2>Câmbio/Exchange</StyledP2>
              </StyledDiv4>
              <StyledWallet>
                <Styledlogo src={logo5} />
                <StyledP2>PIX</StyledP2>
              </StyledWallet>
            </StyledDiv5>
          </StyledDiv3>
        </StyledDiv>
      </StyledSection>
    </FadeInUp>
  );
}

export default BankComponent;
