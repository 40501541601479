import styled from "styled-components";

export const StyledHeader = styled.header`
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: 10 !important;
  display: block;
`;

export const StyledHead = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  width: 100vw;
  height: 84px;
  z-index: 2147483647;
  padding: 22px;
  transition: all 0.3s ease 0s;
  padding-left: 18%;
  padding-right: 18%;
  background-color: #fff;

  @media (max-width: 1200px) {
    padding-left: 0%;
    padding-right: 0%;
  }
`;
export const StyledItens = styled.div`
  display: flex;
  flex-direction: row;
  -moz-box-align: center;
  align-items: center;
  cursor: pointer;
`;
export const StyledMenu = styled.div`
  display: flex;
  flex-direction: row;
  -moz-box-align: center;
  align-items: center;
  padding-left: 0;
  border-left: 1px solid white;
  height: 45px;
`;

export const StyledIten = styled.div`
  display: flex;
  flex-direction: row;
  -moz-box-align: center;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  height: 78px;

  svg { 
  width: 23px;
  height: 22px;
  color: 
  rgb(88, 70, 224);
  }

  @media (max-width: 930px) {
    display: none;
  }

  li{
    display: none;
  }
  &:hover {
    li {
      display: flex;
    }
`;

export const StyledImgMenu = styled.div`
  display: none;
  width: 35px;
  height: 35px;
  margin-right: 26px;
  cursor: pointer;

  svg {
    width: 35px;
    height: 35px;
  }

  @media (max-width: 930px) {
    display: block;
  }
`;

export const StyledImg = styled.img`
  margin-right: 20px;
  width: 82px;
  height: 48px;

  @media (max-width: 930px) {
    margin-left: 40px;
  }
`;

export const StyledA1 = styled.a`
  height: 48px;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  font-size: 0.93rem;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease-out 0s;
  background-color: rgb(255, 255, 255);
  color: #343f8a;
  border: medium none transparent;
  border-radius: 10px;
  padding: 25px 35px;
  margin: 0px;
  text-decoration: none;
`;

export const StyledP1 = styled.a`
  display: flex;
  place-self: auto;
  width: auto;
  margin: 0px;
  margin-right: 0px;
  font-size: 15px;
  line-height: 120%;
  margin-right: 9.26px;
  text-decoration: none;
  color: #343f8a !important;
`;

export const StyledP2 = styled.p`
  display: flex;
  width: auto;
  margin: 0px;
  color: rgb(39, 41, 55);
  font-size: 15px;
  line-height: 120%;
  color: #343f8a;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #343f8a;
  border-radius: 10px 10px 10px 10px;
  padding: 16px 32px 16px 32px;
  &:hover {
    background-color: #343f8a;
    color: #fff;
  }
`;

export const StyledA3 = styled.a`
  color: inherit;
  text-decoration: none;
  color: #343f8a;
`;

export const StyledA4 = styled.a`
  color: inherit;
  text-decoration: none;
  margin-left: 16px;
  color: #343f8a;
`;

export const StyledSpace = styled.div`
  width: 1px;
  height: 24px;
  background-color: #afafaf;
  margin: 0px 16px;
`;

export const StyledSpan = styled.span`
  color: #002130;
  opacity: 0.75;
  font-size: 1.6rem;
  font-weight: bold;
`;

export const StyledSpan2 = styled.span`
  display: block;
  font-size: 1.6rem;
  color: #002130;
  opacity: 0.75;
  font-weight: bold;
  line-height: 20px;
`;

export const StyledImgLogo = styled.img`
  width: 100%;
  display: block;
  color: inherit;
  box-sizing: inherit;
`;

export const StyledButton1 = styled.button`
  color: #675fdc;
  width: auto;
  height: auto;
  display: flex;
  padding: 0;
  min-width: auto;
  align-items: center;
  border-radius: 0;
  margin: 0;
  font-size: 1em;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "EFCircular", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  text-transform: none;
  border: 0;
  cursor: pointer;
  outline: 0;
  position: relative;
  justify-content: center;
  text-decoration: none;
  -moz-user-select: none;
  background-color: transparent;
  -webkit-appearance: none;
`;

export const StyledButton2 = styled.button`
  color: #ffffff;
  width: auto;
  height: auto;
  padding: 8px 16px;
  min-width: auto;
  background: linear-gradient(to bottom, #4085ee 1%, #856af1);
  margin: 0;
  border-radius: 24px;
  font-size: 1em;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "EFCircular", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  text-transform: none;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -moz-user-select: none;
  -webkit-appearance: none;
`;

export const StyledButtonText = styled.span`
  color: inherit;
  width: 100%;
  display: inherit;
  font-size: 1.6rem;
  align-items: inherit;
  font-weight: 900;
  line-height: 24px;
  justify-content: inherit;
  font-family: "EFCircular", sans-serif;
  text-transform: none;
  cursor: pointer;
`;

export const StyledModal = styled.li`

position: absolute;
bottom: -72px;
width: 200px;
height: 75px;
background-color: #0f0f0f;
color: #FFFFFFC2;
padding-left: 20px;

font-family: "Poppins", sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 25.2px;

display: flex;
align-items: center;

&:hover {
  display:flex !important;
}

`