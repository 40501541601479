import { useEffect, useState } from "react";
import Video from "../Video";
import { BoxCount, Card, Container, Section, Text, TextCount } from "./style";

const CountInvest = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let number = 0;
    const countNumber = setInterval(() => {
      if (number === 100) {
        clearInterval(countNumber);
      } else {
        number++;
      }
      setCount(number);
    }, 50);
  }, []);

  return (
    <Container>
      <Section>
        <Video />
        <Card>
          <Text align="end" lineHeight="1.8em">
            A cada dia, mais e mais clientes se juntam aos mais de 100 mil
            investidores que descobriram os enormes benefícios do mercado
            internacional através da PixWave. Nossa empresa foi construída
            especificamente para conectar brasileiros a investimentos no
            exterior e conta com toda a robustez e experiência para executar
            essa missão.
          </Text>
          <BoxCount>
            <TextCount>Investidores</TextCount>
            <p>{count}K+</p>
          </BoxCount>
        </Card>
      </Section>
    </Container>
  );
};

export default CountInvest;
