import styled, { keyframes } from "styled-components";

const BaseAnimation = styled.div`
  animation-duration: ${(props) => props.duration};
  animation-timing-function: ${(props) => props.timingFunction};
  animation-delay: ${(props) => props.delay};
  animation-iteration-count: ${(props) => props.iterationCount};
  animation-direction: ${(props) => props.direction};
  animation-fill-mode: ${(props) => props.fillMode};
  animation-play-state: ${(props) => props.playState};
  display: ${(props) => props.display};
`;
BaseAnimation.defaultProps = {
  duration: "1s",
  timingFunction: "ease",
  delay: "0s",
  iterationCount: "1",
  direction: "normal",
  fillMode: "both",
  playState: "running",
  display: "block",
};

const FadeInUpAnimation = keyframes`  
  from { opacity: 0; transform: translate3d(0,100%,0) }
  to { opacity: 1; transform: none }
`;
export const FadeInUp = styled(BaseAnimation)`
  min-height: auto;
  width: ${(props) => (props.break ? "45vw" : "100vw")};
  animation-name: ${FadeInUpAnimation};

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const FadeInAnimation = keyframes`  
  from { opacity: 0; }
  to { opacity: 1; }
`;
export const FadeIn = styled(BaseAnimation)`
  animation-name: ${FadeInAnimation};
`;

export default BaseAnimation;
