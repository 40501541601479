import HeaderComponent from "../Home/Header";
import FooterComponent from "../Home/Footer";
import ReadyComponent from "../Home/Ready";
import { StyledApp } from "./style";
import MainComponent from "../AboutUs/Main";
import Into from "./Into";
import OpenAccount from "./OpenAccount";
import Deposit from "./Deposit";
import Exchange from "./Exchange";
import Portfolio from "./Portfolio";
import Atendiment from "./Atendiment";
import { useEffect, useState } from "react";
import SideModal from "../SideModal";

function InvestmentComponent() {
  const [showModal, setShowModal] = useState(false);
  const [modalAnimation, setModalAnimation] = useState(0);

  useEffect(() => {
    showModal === true
      ? setTimeout(() => {
          modalAnimation ? setShowModal(true) : setShowModal(false);
        }, 490)
      : modalAnimation
      ? setShowModal(true)
      : setShowModal(false);
  }, [modalAnimation]);
  return (
    <StyledApp>
      {showModal && (
        <SideModal
          setModalAnimation={setModalAnimation}
          modalAnimation={modalAnimation}
        />
      )}
      <HeaderComponent
        setModalAnimation={setModalAnimation}
        modalAnimation={modalAnimation}
      />
      <MainComponent text="Como Investir" home="Home" />
      <Into />
      <OpenAccount />
      <Deposit />
      <Exchange />
      <Portfolio />
      <Atendiment />
      <ReadyComponent />
      <FooterComponent />
    </StyledApp>
  );
}

export default InvestmentComponent;
