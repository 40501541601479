import { useEffect, useState } from "react";
import { FadeInUp } from "../../../Styles";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  StyledButton,
  StyledButton2,
  StyledDiv1,
  StyledDiv2,
  StyledDiv3,
  StyledDiv4,
  StyledDiv5,
  StyledH1,
  StyledMain,
  StyledP,
  StyledSection,
  Styledh2,
  CardButton,
} from "./style";

function MainComponent() {
  const [countPaises, setCountClient] = useState(0);
  const [countTransactions, setCountTransactions] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let numberPaises = 10;
    let numberTransactions = 475;

    const Paises = setInterval(() => {
      if (numberPaises === 48) {
        clearInterval(numberPaises);
      } else {
        numberPaises++;
      }
      setCountClient(numberPaises);
    }, 100);

    const transactions = setInterval(() => {
      if (numberTransactions === 500) {
        clearInterval(transactions);
      } else {
        numberTransactions++;
      }
      setCountTransactions(numberTransactions);
    }, 200);
  }, []);

  return (
    <StyledMain>
      <StyledSection>
        <StyledDiv1>
          <StyledDiv2>
            <FadeInUp duration="1.5s" delay="1s">
              <StyledDiv3>
                <StyledH1>Global, Digital <br/>e Seguro</StyledH1>
                <StyledP>
                  A PixWave tem o plano ideal para você, rompemos a barreira do
                  óbvio para garantir que sua experiência financeira chegue ao nível internacional.
                </StyledP>
                <CardButton>
                  <StyledButton>
                  <a href="https://bank.pixwave.com.br/type-create-account">Abra sua conta</a>
                   </StyledButton>
                  <StyledButton2 onClick={() => navigate("/aboutus")}>
                    Conheça a PixWave
                  </StyledButton2>
                </CardButton>
                <StyledDiv5>
                  <StyledDiv4>
                    <Styledh2>+<></>{countPaises}</Styledh2>
                    <p>Paises</p>
                  </StyledDiv4>
                  <StyledDiv4 marginLeft="20px">
                    <Styledh2><>+</>{countTransactions}MM</Styledh2>
                    <p>Em transações</p>
                  </StyledDiv4>
                </StyledDiv5>
              </StyledDiv3>
            </FadeInUp>
          </StyledDiv2>
        </StyledDiv1>
      </StyledSection>
    </StyledMain>
  );
}

export default MainComponent;
