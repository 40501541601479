import { useSelector } from "react-redux";
import portfolio from "../../../images/portfolio.png";
import { FadeInUp } from "../../../Styles";
import { BoxImg, BoxInfo, Container } from "./style";
import { FaArrowRight } from "react-icons/fa";

const Portfolio = () => {
  let token = useSelector((state) => state).language.Language;

  return (
    <FadeInUp duration="1.5s" delay="1s">
      <Container>
        <BoxImg>
          <img
            src={portfolio}
            alt={token === "Portugues" ? "Criptos moedas" : "Cryptocurrencies"}
          />
        </BoxImg>
        <BoxInfo>
          <h2>Monte seu Portfólio</h2>
          <p>
            Além de contar com os conteúdos produzidos por nosso time de
            estrategistas, soluções e ferramentas de análise na sua decisão de
            onde e como investir, a compra e venda de ativos na plataforma da
            PixWave é totalmente descomplicada.
          </p>
          <button>
            <a href="https://bank.pixwave.com.br/type-create-account">Abra sua conta</a>
            <FaArrowRight />
          </button>
        </BoxInfo>
      </Container>
    </FadeInUp>
  );
};

export default Portfolio;
