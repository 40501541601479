import logo2 from "../../../images/coins.png";
import logo3 from "../../../images/Pix.png";
import { StyledDiv, StyledDiv3, StyledDiv4, StyledDiv4blue, StyledDiv5, StyledDiv7, StyledDivTitle, StyledH1, StyledP, StyledP2, StyledSection, Styledlogo } from "./style";
import {
  FaUnlockAlt,
  FaDollarSign,
  FaWallet,
} from "react-icons/fa";
import {
  FiSmartphone,
} from "react-icons/fi";

function BankComponent() {
    return (
      <StyledSection>
        <StyledDivTitle>
        As multivantagens de ter uma <br />conta PJ da PixWave
        </StyledDivTitle>
        <StyledP>Existem diversos motivos para se ter uma conta PJ e, na PixWave, esses motivos são ainda melhores:</StyledP>
        <StyledDiv>
          <StyledDiv3>
            <StyledDiv5 >
              <StyledDiv4blue>
              <FiSmartphone />
                <StyledDiv7>
                  <StyledH1>Conta PJ Digital</StyledH1>
                  <StyledP2>Tudo que você precisa de forma 100% digital.</StyledP2>
                </StyledDiv7>
              </StyledDiv4blue>
              <StyledDiv4>
                <FaUnlockAlt />
                <StyledDiv7>
                  <StyledH1>Open Finance</StyledH1>
                  <StyledP2>Conecte contas PJ de outros bancos, visualize todos os extratos da sua empresa em um único lugar.</StyledP2>
                </StyledDiv7>
              </StyledDiv4>
              <StyledDiv4>
                <FaWallet />
                <StyledDiv7>
                  <StyledH1>Carteira</StyledH1>
                  <StyledP2>Faça pagamentos por aproximação ou utilize os cartões PixWave nas principais carteiras digitais para simplificar seu dia a dia.</StyledP2>
                </StyledDiv7>
              </StyledDiv4>
            </StyledDiv5 >
            <StyledDiv5 >
              <StyledDiv4blue>
                <Styledlogo src={logo3} />
                <StyledDiv7>
                  <StyledH1>PIX</StyledH1>
                  <StyledP2>Receba suas transferências em segundos.</StyledP2>
                </StyledDiv7>
              </StyledDiv4blue>
              <StyledDiv4>
                <FaDollarSign />
                <StyledDiv7>
                  <StyledH1>PW PARTNER</StyledH1>
                  <StyledP2>Produto exclusivo que proporciona serviços, ofertas e benefícios exclusivos para o seu negócio.</StyledP2>
                </StyledDiv7>
              </StyledDiv4>
              <StyledDiv4>
              <Styledlogo src={logo2}/>
                 <StyledDiv7>
                  <StyledH1>Câmbio/Exchange</StyledH1>
                  <StyledP2>Sua conta PixWave PJ é global, Faça câmbio pela plataforma com a cotação comercial de mais de 100 moedas e economize.</StyledP2>
                </StyledDiv7>
              </StyledDiv4>
              </StyledDiv5 >
          </StyledDiv3>
        </StyledDiv>
      </StyledSection>

    );
  }
  
  export default BankComponent;