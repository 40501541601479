import {
  ContainerMain,
  BoxSection,
  BoxOpacity,
  Header,
  ContainerHeader,
  StyledP
} from "./style";
import { FadeInUp } from "../../../Styles";
import {
  BiRightArrowAlt
} from "react-icons/bi";

function MainComponent() {
  return (
    <ContainerMain>
      <BoxSection>
        <BoxOpacity>
          <ContainerHeader>
            <FadeInUp duration="1.5s" delay="1s">
              <Header>FAQs</Header>
          <StyledP>
            Home <BiRightArrowAlt />
            Sobre a Empresa
          </StyledP>
            </FadeInUp>
          </ContainerHeader>
        </BoxOpacity>
      </BoxSection>
    </ContainerMain>
  );
}

export default MainComponent;
