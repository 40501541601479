import styled from "styled-components";
import circleImg from "../../../images/circle.png";
import mainImg from "../../../images/bg_overlay3.png";

export const StyledSection = styled.section`
  height: 900px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    margin-bottom: 210px;
    margin-top: 115px;
    height: 1035px;
  }

  @media (max-width: 700px) {
    margin-bottom: 160px;
  }

  @media (max-width: 600px) {
    margin-bottom: 130px;
  }
`;

export const Opacity = styled.div`
  background-image: url(${mainImg});
  background-position: center left;
  background-size: cover;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledDiv = styled.div`
  text-align: center;
  display: block;
  position: relative;
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 800px) {
    width: 100%;
    flex-direction: column-reverse;
  }
`;

export const StyledDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media (max-width: 800px) {
    align-items: center;
  }
`;

export const StyledImg = styled.img`
  width: 416px;
  height: 477px;

  @media (max-width: 800px) {
    width: 80%;
    height: 85%;
  }
`;

export const StyleddivImg = styled.div`
  background-image: url(${circleImg});
  width: 578.79px;
  height: 660px;

  @media (max-width: 800px) {
    width: 80%;
    height: 85%;
  }
`;

export const StyledBr = styled.br`
  @media (max-width: 800px) {
    display: none;
  }
`;

export const StyledBr2 = styled.br`
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const StyledDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 40%;
  background-size: cover;

  padding-left: 100px;
  @media (max-width: 800px) {
    align-items: center;
    padding-left: 0;
  }
`;

export const StyledH1 = styled.h1`
  width: 500px;
  height: 215px;
  text-align: left;

  color: rgb(52, 63, 138);
  font-family: "Poppins", sans-serif;
  font-size: 38px;
  font-weight: 600;
  hyphens: manual;
  line-height: 58.8px;

  letter-spacing: 0.1px;
  margin-bottom: 20px;
  @media (max-width: 900px) {
    font-size: 28px;
    padding-left: 20px;
  }

  @media (max-width: 800px) {
    font-size: 25px;
    width: 300px;
    text-align: center;
    padding-left: 0px;
  }
`;

export const StyledP = styled.p`
  width: 489px;
  height: 76px;

  text-align: left;
  color: #9599bb;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #9599bb;
  margin-top: 5px;

  @media (max-width: 1000px) {
    width: 250px;
    padding-left: 20px;
  }

  @media (max-width: 900px) {
    width: 80%;
    font-size: 14px;
    text-align: center;
    margin-top: 40px;
    max-width: 322px;
    padding-left: 0px;
  }
`;
export const StyledDiv6 = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: none;

  @media (max-width: 800px) {
    display: flex;
  }
`;

export const StyledDiv4 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 40%;
  background-size: cover;
  align-items: center;
  display: none;
  margin-bottom: 50px;
  @media (max-width: 800px) {
    display: flex;
  }
`;

export const StyledH2 = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 58px;
  line-height: 40px;
  text-align: center;
  height: 105px;

  color: #27348a;
  display: none;
  @media (max-width: 800px) {
    display: flex;
  }
`;

export const StyledP2 = styled.p`
  text-align: left;
  color: #9599bb;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 51px;
  display: none;
  text-align: center;

  @media (max-width: 800px) {
    display: unset;
    width: 325px;
  }
`;

export const StyledButton = styled.div`
  box-sizing: border-box;

  width: 137px;
  height: 32px;
  background: #009ee2;
  border: 2px solid #009ee2;
  border-radius: 2px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;

  text-align: center;

  color: #ffffff;
  margin-top: 10px;
  display: none;

  @media (max-width: 800px) {
    display: unset;
  }
`;
