import styled from "styled-components";

export const StyledApp = styled.div`
  background: white;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 900px;
`;

export const Container = styled.div`
  flex: 1 0 auto;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  margin-bottom: ${(props) => props.marginBottom};
`;
