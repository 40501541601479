import styled from "styled-components";
import mainImg from "../../../images/bg_overlay3.png";

export const Opacity = styled.div`
  background-image: url(${mainImg});
  transform: scaleX(-1);
  background-position: right;
  background-size: cover;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 10;
`;

export const Container = styled.div`
  position: relative;
  flex: 1 0 auto;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 40px;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 1220px) {
    flex-direction: column-reverse;
    padding-top: 50px;
  }

  @media (max-width: 1220px) and (min-width: 801px) {
    position: relative;
    height: 804px;
    margin: 50px 0;
    padding: 0;
  }
`;

export const InfoText = styled.div`
  width: 400px;
  height: 378px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f9ff;
  border-radius: 10px 0px 0px 10px;
  padding: 0 50px 0 20px;

  div {
    width: 100%;
  }

  @media (max-width: 650px) {
    margin-right: 0;
    width: 336px;
    height: auto;
    text-align: center;
    padding: 50px 10px 10px 10px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 800px) and (min-width: 651px) {
    width: 631px;
    height: 230px;
    text-align: center;
    margin-right: 0;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 1220px) and (min-width: 801px) {
    width: 949px;
    height: 247px;
    text-align: center;
    margin-right: 0;
    padding: 50px 10px 10px 10px;
    position: absolute;
    bottom: 0;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const BoxImage = styled.div`
  width: 800px;
  height: 534px;
  div {
    width: 100%;
  }
  @media (max-width: 650px) {
    width: 336px;
    height: 224px;
  }

  @media (max-width: 800px) and (min-width: 651px) {
    width: 631px;
    height: 421px;
  }
  @media (max-width: 1220px) and (min-width: 801px) {
    width: 813px;
    height: 542px;
    position: absolute;
    top: 70px;
  }
`;

export const Image = styled.img`
  border: solid none;
  box-sizing: border-box;
  width: 800px;
  height: 534px;

  @media (max-width: 650px) {
    width: 336px;
    height: 224px;
  }

  @media (max-width: 800px) and (min-width: 651px) {
    width: 631px;
    height: 421px;
  }
  @media (max-width: 1220px) and (min-width: 801px) {
    width: 813px;
    height: 542px;
  }
`;

export const Header = styled.h2`
  font-family: "Poppins", sans-serif;
  color: #343f8a;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.4em;
  padding-left: 20px;
  width: 60%;
  @media (max-width: 800px) {
    font-size: 28px;
    width: 30%;
    padding: 0;
  }
  @media (max-width: 1220px) and (min-width: 801px) {
    font-size: 33px;
    width: 30%;
    padding: 0;
  }
`;

export const TextArea = styled.p`
  vertical-align: baseline;
  list-style-type: none;
  color: #9599bb;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding-left: 20px;
  background: transparent;
  margin-top: 20px;

  @media (max-width: 1220px) and (min-width: 801px) {
    width: 813px;
  }

  @media (max-width: 650px) {
    padding-left: 0px;
  }
`;
