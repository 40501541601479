import { useEffect, useState } from "react";

import ReadyComponent from "../Home/Ready";
import { StyledApp } from "../Faq/style";
import FooterComponent from "../Home/Footer";
import HeaderComponent from "../Home/Header";
import {
  StyledContainer,
  StyledSubTitle,
  StyledTConten,
  StyledTitle,
} from "./style";
import { ContainerMain } from "../Faq/Main/style";
import SideModal from "../SideModal";

function PrivacyComponent() {
  const [showModal, setShowModal] = useState(false);
  const [modalAnimation, setModalAnimation] = useState(0);

  useEffect(() => {
    showModal === true
      ? setTimeout(() => {
          modalAnimation ? setShowModal(true) : setShowModal(false);
        }, 490)
      : modalAnimation
      ? setShowModal(true)
      : setShowModal(false);
  }, [modalAnimation]);
  return (
    <StyledApp>
      {showModal && (
        <SideModal
          setModalAnimation={setModalAnimation}
          modalAnimation={modalAnimation}
        />
      )}
      <HeaderComponent
        setModalAnimation={setModalAnimation}
        modalAnimation={modalAnimation}
      />
      <ContainerMain>
        <StyledContainer>
          <StyledTConten>
            <StyledTitle>Quem somos</StyledTitle>
            <StyledSubTitle>
              O endereço do nosso site é: pixwave.com.br
            </StyledSubTitle>
          </StyledTConten>

          <StyledTConten>
            <StyledTitle>
              <strong>Comentários</strong>
            </StyledTitle>
            <StyledSubTitle>
              Quando os visitantes deixam comentários no site, coletamos os
              dados mostrados no formulário de comentários, além do endereço de
              IP e de dados do navegador do visitante, para auxiliar na detecção
              de spam. <br /> <br />
              Uma sequência anonimizada de caracteres criada a partir do seu
              e-mail (também chamada de hash) poderá ser enviada para a PixWave
              para verificar se você usa o serviço. Depois da aprovação do seu
              comentário, a foto do seu perfil fica visível publicamente junto
              de seu comentário.
            </StyledSubTitle>
          </StyledTConten>

          <StyledTConten>
            <StyledTitle>
              <strong>Mídia</strong>
            </StyledTitle>
            <StyledSubTitle>
              Se você envia imagens para o site, evite enviar as que contenham
              dados de localização incorporados (EXIF GPS). Visitantes podem
              baixar estas imagens do site e extrair delas seus dados de
              localização.
            </StyledSubTitle>
          </StyledTConten>

          <StyledTConten>
            <StyledTitle>Cookies</StyledTitle>
            <StyledSubTitle>
              Ao deixar um comentário no site, você poderá optar por salvar seu
              nome, e-mail e site nos cookies. Isso visa seu conforto, assim
              você não precisará preencher seus dados novamente quando fizer
              outro comentário. Estes cookies duram um ano. <br />
              <br />
              Se você tem uma conta e acessa este site, um cookie temporário
              será criado para determinar se seu navegador aceita cookies. Ele
              não contém nenhum dado pessoal e será descartado quando você
              fechar seu navegador.
              <br />
              <br />
              Quando você acessa sua conta no site, também criamos vários
              cookies para salvar os dados da sua conta e suas escolhas de
              exibição de tela. Cookies de login são mantidos por dois dias e
              cookies de opções de tela por um ano. Se você selecionar
              “Lembrar-me”, seu acesso será mantido por duas semanas. Se você se
              desconectar da sua conta, os cookies de login serão removidos.
              <br />
              Se você editar ou publicar um artigo, um cookie adicional será
              salvo no seu navegador. Este cookie não inclui nenhum dado pessoal
              e simplesmente indica o ID do post referente ao artigo que você
              acabou de editar. Ele expira depois de 1 dia.
              <br />
              <br />
            </StyledSubTitle>
          </StyledTConten>

          <StyledTConten>
            <StyledTitle>Mídia incorporada de outros sites</StyledTitle>
            <StyledSubTitle>
              Artigos neste site podem incluir conteúdo incorporado como, por
              exemplo, vídeos, imagens, artigos, etc. Conteúdos incorporados de
              outros sites se comportam exatamente da mesma forma como se o
              visitante estivesse visitando o outro site. <br /> <br />
              Estes sites podem coletar dados sobre você, usar cookies,
              incorporar rastreamento adicional de terceiros e monitorar sua
              interação com este conteúdo incorporado, incluindo sua interação
              com o conteúdo incorporado se você tem uma conta e está conectado
              com o site.
            </StyledSubTitle>
          </StyledTConten>

          <StyledTConten>
            <StyledTitle>Com quem partilhamos seus dados</StyledTitle>
            <StyledSubTitle>
              Se você solicitar uma redefinição de senha, seu endereço de IP
              será incluído no e-mail de redefinição de senha.
            </StyledSubTitle>
          </StyledTConten>

          <StyledTConten>
            <StyledTitle>Por quanto tempo mantemos os seus dados</StyledTitle>
            <StyledSubTitle>
              Se você deixar um comentário, o comentário e os seus metadados são
              conservados indefinidamente. Fazemos isso para que seja possível
              reconhecer e aprovar automaticamente qualquer comentário posterior
              ao invés de retê-lo para moderação. <br />
              <br />
              Para usuários que se registram no nosso site (se houver), também
              guardamos as informações pessoais que fornecem no seu perfil de
              usuário. Todos os usuários podem ver, editar ou excluir suas
              informações pessoais a qualquer momento (só não é possível alterar
              o seu username). Os administradores de sites também podem ver e
              editar estas informações.
            </StyledSubTitle>
          </StyledTConten>

          <StyledTConten>
            <StyledTitle>Quais os seus direitos sobre seus dados</StyledTitle>
            <StyledSubTitle>
              Se você tiver uma conta neste site ou se tiver deixado
              comentários, pode solicitar um arquivo exportado dos dados
              pessoais que mantemos sobre você, inclusive quaisquer dados que
              nos tenha fornecido. Também pode solicitar que removamos qualquer
              dado pessoal que mantemos sobre você. Isto não inclui nenhuns
              dados que somos obrigados a manter para propósitos
              administrativos, legais ou de segurança.
            </StyledSubTitle>
          </StyledTConten>

          <StyledTConten>
            <StyledTitle>Para onde enviamos seus dados</StyledTitle>
            <StyledSubTitle>
              Comentários de visitantes podem ser marcados por um serviço
              automático de detecção de spam.
            </StyledSubTitle>
          </StyledTConten>
        </StyledContainer>
      </ContainerMain>
      <ReadyComponent></ReadyComponent>
      <FooterComponent />
    </StyledApp>
  );
}

export default PrivacyComponent;
