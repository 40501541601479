import { createSlice } from "@reduxjs/toolkit";

export const Slice = createSlice({
    name: 'language',
    initialState: {
        Language: 'Portugues',
    },
    reducers: {
        change(state, { payload }){
            localStorage.setItem("Language", payload);
            return {...state, Language: payload}
        }
    }
})

export const { change } = Slice.actions

export const selectLanguage = state => state.language

export default Slice.reducer