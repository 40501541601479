import cardImage from "../../../images/Card.png";
import { FadeIn } from "../../../Styles";
import {
  StyledDiv,
  StyledDiv2,
  StyledDiv3,
  StyledH1,
  StyledImg,
  StyledP,
  StyledSection,
  StyledButton,
} from "./style";
import { FaArrowRight } from "react-icons/fa";

function CardComponent() {
  return (
    <StyledSection>
      <StyledDiv>
        <StyledDiv2>
          <FadeIn duration="1.5s" delay="1s">
            <StyledImg src={cardImage} />
          </FadeIn>
        </StyledDiv2>
        <FadeIn duration="1.5s" delay="1s">
          <StyledDiv3>
            <StyledH1>Acesso ao inexplorado com a PixWave</StyledH1>
            <StyledP>
              Uma plataforma segura, transparente e digital com acesso a
              diversos produtos e serviços.
            </StyledP>
            <StyledButton>
              <a href="https://bank.pixwave.com.br/type-create-account">Abra sua conta</a>
              <FaArrowRight />
            </StyledButton>
          </StyledDiv3>
        </FadeIn>
      </StyledDiv>
    </StyledSection>
  );
}

export default CardComponent;
