import styled from "styled-components";
import backService from "../../../images/background_service.png";
import circle from "../../../images/circleComplete.png";

export const Container = styled.div`
  flex: 1 0 auto;

  position: relative;
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  padding: 50px 0;

  @media (max-width: 800px) {
    padding: 20px 0;
    height: auto;
  }
`;

export const ContainerOpacity = styled.div`
  background-image: url(${backService});
  background-position: 0px -165px;
  background-size: 96% auto;
  opacity: 0.08;
  position: absolute;
  width: 100%;

  height: 100%;
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 1140px;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    width: 100%;
  }
  @media (max-width: 1200px) and (min-width: 801px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const BoxImg = styled.div`
  text-align: center;
  width: 500px;
  div {
    background-position: center center;
    background-size: contain;

    background-image: url(${circle});
  }

  img {
    width: 371px;
    height: 528px;
  }

  @media (max-width: 650px) {
    width: 338px;
    img {
      width: 270px;
      height: 385px;
    }
  }

  @media (max-width: 800px) and (min-width: 651px) {
    width: 93%;
    img {
      width: 506px;
      height: 721px;
    }
  }

  @media (max-width: 1200px) and (min-width: 801px) {
    width: 474px;
    height: 676px;

    div {
      height: 550px;
    }
    img {
      width: 475px;
      height: 676px;
    }
  }
`;

export const BoxText = styled.div`
  width: 578px;
  padding-left: 50px;
  div {
    width: 100%;
  }
  h2 {
    color: #343f8a;
    font-size: 42px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    line-height: 1.4em;
  }

  p {
    margin: 20px 0;
    line-height: 1.8em;
    color: #343f8a;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }

  @media (max-width: 800px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px;

    div {
      justify-content: center;
    }
  }

  @media (max-width: 650px) {
    padding: 20px;
    h2 {
      font-size: 28px;
    }
  }

  @media (max-width: 1220px) and (min-width: 801px) {
    width: 474px;
    h2 {
      font-size: 33px;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
  p {
    margin: 0;
    line-height: 1.8em;
    color: #343f8a;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
  }

  @media (max-width: 1200px) and (min-width: 801px) {
    display: none;
  }
`;

export const Button = styled.div`
  margin-top: 50px;
  button {
    width: 200px;
    height: 60px;
    background: #343f8a;
    color: #fff;

    border: 2px solid #343f8a;
    border-radius: 5px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;

    p {
      margin-right: 6px;
      color: #fff;
    }

    &:hover {
      color: #343f8a;
      background-color: #fff;

      p {
        color: #343f8a;
      }
    }
  }
  @media (max-width: 800px) {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
`;
