import HomeComponent from "./Home";
import FaqComponent from "./Faq";
import BusinessComponent from "./Business";
import ServiceComponent from "./Services";
import InvestmentComponent from "./Investment";
import AboutUsComponent from "./AboutUs";
import PrivacyComponent from "./Privacy";

export const Home = HomeComponent;
export const Faq = FaqComponent;
export const Business = BusinessComponent;
export const Service = ServiceComponent;
export const Investment = InvestmentComponent;
export const AboutUs = AboutUsComponent;
export const Privacy = PrivacyComponent;
