import {
  StyledButton,
  StyledDiv,
  StyledDiv2,
  StyledDiv3,
  StyledDiv4,
  StyledDiv4blue,
  StyledDiv5,
  StyledH1,
  StyledP,
  StyledP2,
  StyledP2Center,
  StyledSection,
} from "./style";
import {
  FaSearchDollar,
  FaUnlockAlt,
  FaSyncAlt,
  FaQrcode,
} from "react-icons/fa";

function IntroComponent() {
  return (
    <StyledSection>
      <StyledDiv>
        <StyledDiv2>
          <StyledH1>Uma escolha certa pode mudar tudo.</StyledH1>
          <StyledP>
            A PixWave criou uma plataforma 100% digital para empresas, onde seu
            negócio tem todo suporte para crescer em qualquer cenário.
          </StyledP>
          <a href="https://bank.pixwave.com.br/requirements-create-account?type=pj">Crie sua conta PJ</a>
        </StyledDiv2>
        <StyledDiv3>
          <StyledDiv5>
            <StyledDiv4>
              <div>
                <FaUnlockAlt />
              </div>
              <StyledP2>Soluções de pagamento</StyledP2>
            </StyledDiv4>
            <StyledDiv4blue>
              <div>
                <FaSearchDollar />
              </div>
              <StyledP2>Nada de taxas escondidas</StyledP2>
            </StyledDiv4blue>
          </StyledDiv5>
          <StyledDiv5>
            <StyledDiv4>
              <div>
                <FaSyncAlt />
              </div>
              <StyledP2>Tudo em uma plataforma</StyledP2>
            </StyledDiv4>
            <StyledDiv4>
              <div>
                <FaQrcode />
              </div>
              <StyledP2Center padding='20px'>Open Finance</StyledP2Center>
            </StyledDiv4>
          </StyledDiv5>
        </StyledDiv3>
      </StyledDiv>
    </StyledSection>
  );
}

export default IntroComponent;
