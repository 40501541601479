import styled from "styled-components";
import mainImg from "../../../images/cardBackGround.png";
import circleImg from "../../../images/circleComplete.png";

export const StyledSection = styled.section`
  background-image: url(${mainImg});
  background-size: cover;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  background-position: right;
  background-color: #343f8a;
  margin-top: 30px;
`;

export const StyledDiv = styled.div`
  text-align: center;
  display: block;

  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 50px 0;
  }
`;

export const StyledDiv2 = styled.div`
  width: 570px;
  height: 526px;
  background-image: url(${circleImg});
  background-size: contain;
  background-position: center;

  @media (max-width: 1220px) and (min-width: 801px) {
    width: 474px;
    height: 397px;
  }
  @media (max-width: 800px) {
    width: 90%;
    margin-top: 40px;
  }
  @media (max-width: 660px) {
    height: 310px;
    width: 100%;
  }
`;

export const StyledImg = styled.img`
  width: 670px;
  height: 527px;
  @media (max-width: 1220px) and (min-width: 801px) {
    width: 505px;
    height: 397px;
  }
  @media (max-width: 800px) {
    width: 673px;
    height: 529px;
  }
  @media (max-width: 650px) {
    width: 399px;
    height: 314px;
  }
`;

export const StyledDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  width: unset;
  padding-left: 70px;
  @media (max-width: 800px) {
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const StyledH1 = styled.h1`
  width: 555px;
  height: auto;

  color: #fff;
  text-align: left;

  font-family: "Poppins", sans-serif;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.4em;

  @media (max-width: 1220px) and (min-width: 801px) {
    width: 450px;
    font-size: 32px;
  }

  @media (max-width: 800px) {
    text-align: center;
    width: 100%;
    height: auto;
    font-size: 28px;
    padding-right: 0px;
  }
`;

export const StyledP = styled.p`
  width: 489px;
  height: auto;
  text-align: left;
  color: #fff;

  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
  margin-bottom: 14.4px;

  @media (max-width: 800px) {
    text-align: center;
    width: 100%;
    padding-right: 0px;
    margin: 20px 0;
  }
`;
export const StyledButton = styled.button`
  width: 200px;
  height: 60px;
  background: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  cursor: pointer;
  color: rgb(52, 63, 138);
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #fff;
    background-color: rgb(52, 63, 138);
  }

  p {
    margin-right: 6px;
  }
  @media (max-width: 650px) {
    margin-top: 20px;
  }
`;

export const StyledSeta = styled.img`
  width: 21px;
  height: 17px;
`;
