import React from 'react';
import footImg from "../../../images/footerImg.png";
import {
    StyledA,
    StyledContaier,
    StyledFoot,
    StyledFooter,
    StyledFooterInside,
    StyledImg,
    StyledIten,
    StyledItens,
    StyledItentext,
    StyledLi,
    StyledList,
    StyledP1,
    StyledPolitic,
    StyledRow,
    StyledRowIten,
    StyledRowIten2,
    StyledText
} from "./style";

function FooterComponent() {
    return (
        <StyledFooter>
            <StyledFoot>
                <StyledFooterInside>
                    <StyledItens>
                        <StyledImg src={footImg} />
                        <StyledItentext>
                            <StyledText>
                                A PixWave é um banco digital que torna a sua vida financeira cada vez mais segura, simples e eficaz. Chegamos para revolucionar o mercado financeiro.
                            </StyledText>
                        </StyledItentext>
                    </StyledItens>

                    <StyledIten>
                        <StyledP1>Institucional</StyledP1>
                        <StyledList>
                            <StyledLi>
                                <StyledA href="/aboutus">Sobre a PixWave</StyledA>
                            </StyledLi>
                            <StyledLi>
                                <StyledA href="/">Termos de Uso</StyledA>
                            </StyledLi>
                            <StyledLi>
                                <StyledA href="/investment">Como Investir</StyledA>
                            </StyledLi>
                        </StyledList>
                    </StyledIten>

                    <StyledIten>
                        <StyledP1>Serviços</StyledP1>
                        <StyledList>
                            <StyledLi>
                                <StyledA href="/service">Para Você</StyledA>
                            </StyledLi>
                            <StyledLi>
                                <StyledA href="/service">Para Empresas</StyledA>
                            </StyledLi>
                            <StyledLi>
                                <StyledA href="/service">Conta 100% Digital</StyledA>
                            </StyledLi>
                            <StyledLi>
                                <StyledA href="/service">Câmbio/Exchange</StyledA>
                            </StyledLi>
                            <StyledLi>
                                <StyledA href="/service">Carteira Digital</StyledA>
                            </StyledLi>
                            <StyledLi>
                                <StyledA href="/service">PW | Partner</StyledA>
                            </StyledLi>
                            <StyledLi>
                                <StyledA href="/service">Pix</StyledA>
                            </StyledLi>
                        </StyledList>
                    </StyledIten>

                    <StyledIten>
                        <StyledP1>Ajuda</StyledP1>
                        <StyledList>
                        <StyledA href="/faq">Perguntas Frequentes</StyledA>
                        </StyledList>
                    </StyledIten>

                    <StyledIten>
                        <StyledP1>Offices</StyledP1>
                        <StyledList>
                            <StyledLi>
                            </StyledLi>
                            <div><strong>GLOBAL</strong></div>
                            <div style={{ marginBottom: '10px' }}>3363 NE 163rd Street, Suite 704, North Miami Beach, FL 33160</div>
                            <div><strong>SÃO PAULO</strong></div>
                            <div style={{ marginBottom: '35px' }}>Juscelino Kubitschek, 1545</div>
                            <div>E-mail</div>
                            <div><StyledA href="mailto:minhaconta@pixwave.com.br">minhaconta@pixwave.com.br</StyledA></div>
                        </StyledList>
                    </StyledIten>

                </StyledFooterInside>
            </StyledFoot>
            <StyledPolitic>
                <StyledContaier>
                    <StyledRow>
                        <StyledRowIten>
                            <span>Copyright © 2023 PixWave</span>
                        </StyledRowIten>
                        <StyledRowIten2>
                            <StyledA href="/politicas">Política de Privacidade</StyledA>
                            <span>Termos de Uso</span>
                        </StyledRowIten2>
                    </StyledRow>
                </StyledContaier>
            </StyledPolitic>
        </StyledFooter>
    );
}

export default FooterComponent;
